export const tabs_init = () => {
  $(document).ready(function () {
    // activate tab from url
    if (window.location.hash) {
      $('.nav[role="tablist"] a[href="' + window.location.hash + '"]').tab('show')
    }

    // modify url from tabs (without jumping viewport to anchor)
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (event) {
      var scrollmem = $('body').scrollTop() || $('html').scrollTop()
      window.location.hash = event.target.hash
      $('html,body').scrollTop(scrollmem)
    })
  })
}