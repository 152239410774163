<script>

  import { mapState } from 'vuex'

  import './style.scss'

  export default {
    name: 'NotActivatedProgramRow',
    props: {
      country_id:       { type: Number,  required: true  },
      program:          { type: Object,  required: true  },
      device:           { type: String,  required: true  },
      new_activations:  { type: Object,  required: true  },
    },
    data: function() {
      return {
        send_activate_ticket_url: this.program.send_activate_ticket_url
      }
    },
    mounted: function() {
      // for RSPEC
      window[this.activate_offer_btn_id] = this
    },
    computed: {
      ...mapState([
        'translations'
      ]),
      is_activation_request_sent() {
        return !!this.new_activations[this.program.program_id]
      },
      is_program_active() {
        return this.program.program_status === 'active'
      },
      is_auto_activated() {
        return this.program.auto_activated
      },
      is_just_activated() {
        return !!this.program.just_activated
      },
      tr_class() {
        let res
        if (this.is_just_activated) {
          res = { 'table-success': true, 'just-activated': true }
        } else {
          res = { 'table-danger': true }
        }
        return res
      },
      activate_offer_btn_id() {
        let res = []
        res.push('activate_offer')
        res.push(this.country_id)
        res.push(this.program.program_id)
        res.push(this.device)
        return res.join('_')
      }
    },
    methods: {
      onClickActivate: function(url) {
        this.$store.dispatch('activateOffer', { url: url, country_id: this.country_id, device: this.device })
      },
      onClickActivateOfferModal: function() {
        $('#activateOfferTicketModal form').prop('action', this.send_activate_ticket_url)
        // сразу после открытия модального окна "готовимся" к успешному
        // ответу на запрос по созданию тикета на активацию оффера --
        // цепляем к window колбэк, который в store в объект new_activations
        // сделает пометку, что запрос был послан, тогда во вью
        // в этой строке исчезнет кнопка и появится надпись "Request sent".
        window.onActivateOfferSent = this.make_program_just_activated
      },
      make_program_just_activated: function () {
        this.$store.dispatch('makeOfferJustActivated', { program_id: this.program.program_id })
      }
    }
  }
</script>

<template>
  <tr :class="tr_class">
    <td></td>
    <td>
      <strong>
        {{ program.name }}
        <template v-if="is_activation_request_sent">
          <small class="text-success float-right">Request sent</small>
        </template>
        <template v-else>
          <fragment v-if="is_program_active">
            <fragment v-if="is_auto_activated">
              <a class="btn btn-success btn-sm float-right btn_activate_offer" @click="onClickActivate(program.activate_path)" role="button">Activate</a>
            </fragment>
            <fragment v-else>
              <a class="btn btn-primary btn-sm float-right" :id="activate_offer_btn_id" href="#activate_offer_ticket_modal" role="button" data-toggle="modal" data-target="#activateOfferTicketModal" @click="onClickActivateOfferModal()">{{ translations.activate }}</a>
            </fragment>
          </fragment>
          <fragment v-else>
            <small class="text-danger float-right">
              On pause
            </small>
          </fragment>
        </template>
      </strong>
    </td>
    <td><p>{{ program.category_title }}</p></td>
    <td><p style="font-size: 18px">{{ program.profit }}</p></td>
  </tr>
</template>