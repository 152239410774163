/*

:: CASE 1 ::

    Ошибка с указанием модели (landings), записи(22305), и поля(clicks):
    {
      "errors": {
        "landings": {
          "22305": {
            "clicks": [
              "must be greater than or equal to 0"
            ]
          }
        }
      }
    }

    JS найдёт инпут:

    <div class="form-group">
      <input name="landings[22305][clicks]">
    </div>

    и добавит сообщение об ошибке под него.


:: CASE 2 ::

    Ошибка с указанием модели(landings) только
    {
      "errors": {
        "landings": [
          "is missing"
        ]
      }
    }

    Будет показан `toastr` с сообщением "landings is missing"


*/

import toastr from "toastr";

export const vue_render_validation_errors = function(errors) {
  if (isObject(errors)) {
    Object.keys(errors).forEach(model => {
      if (isArray(errors[model])) {
        displayModelErrors(errors, model)
      } else {
        parseRecordsErrors(errors, model)
      }
    })
  }
}

export const vue_clear_validation_errors = function(opts) {
  if (opts.form) {
    $(`${opts.form} .form-group.has-error`).each(function(){
      $('.invalid-feedback', $(this)).remove()
      $(this).removeClass('has-error')
    })
  }
}

const parseRecordsErrors = function(errors, model) {
  Object.keys(errors[model]).forEach(record => {
    Object.keys(errors[model][record]).forEach(field => {
      let messages  = errors[model][record][field]
      let inputName = `${model}[${record}][${field}]`
      let $input    = $(`[name="${inputName}"]`)

      let $error = $('<div class="invalid-feedback"></div>')
      $error.html(messages[0])
      $input.closest('.form-group').addClass('has-error').append($error)
    })
  })
}

const displayModelErrors = function(errors, model) {
  if (errors[model].length) {
    toastr['error'](model + ' - ' + errors[model][0])
  }
}