
window.i18n = {
  en: {
    save: 'Save',
    secondsToNextAttempts: 'Send again in {seconds} sec.',
    minutes_ago: '{minutes} min. ago',
    groups: {
      date: 'Date',
      country_id: 'Country',
      program_id: 'Program',
      user_subaccount_id: 'Tracklink',
      source_id: 'Source',
      landing_id: 'Landing page'
    },
    stats: {
      columns: {
        name: 'Grouping 1',
        p1: 'Grouping 2',
        p2: 'Grouping 3',
        p3: 'Grouping 4',
        p4: 'Grouping 5',
        uniqs: 'Uniques',
        visits: 'Visits',
        tb: 'Traffic back',
        cr: 'CR',
        epc: 'EPC',
        ecpm: 'eCPM',
        rec: 'Approved',
        wai: 'Pending',
        rej: 'Declined',
        all_l: 'Total',
        rec_s: 'Approved',
        wai_s: 'Pending',
        rej_s: 'Declined',
        all_s: 'Total'
      },
      groups: {
        percent: 'Conversion Percent',
        conversion: 'Conversions',
        profit: 'Profit'
      }
    },
    datepicker: {
      applyLabel: 'Submit',
      cancelLabel: 'Cancel',
      customRangeLabel: 'Your period',
      daysOfWeek: {
        sunday: 'Su',
        monday: 'Mo',
        tuestay: 'Tu',
        wednesday: 'We',
        thursday: 'Th',
        friday: 'Fr',
        saturday: 'Sa'
      },
      monthNames: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December'
      },
      ranges: {
        today: 'Today',
        yesterday: 'Yesterday',
        last_seven_days: 'Last 7 days',
        last_thirty_days: 'Last 30 days',
        this_month: 'Current month',
        last_month: 'Previous month'
      }
    },
    download_selected: 'Download selected',
    select_column: 'Please select columns by right click',
    try_postback: {
      url_requested: 'Url requested',
      params_passed: 'Params passed',
      unknown_params: 'Unknown params',
      response_received: 'Response received',
      result: 'Result'
    },
    transactions: {
      choose_type: 'Choose the transaction type'
    }
  },
  bn: {
    save: 'সংরক্ষণ করুন',
    secondsToNextAttempts: '{seconds} সেকেন্ডের মধ্যে আবার প্রেরণ করুন।.',
    minutes_ago: 'ম{minutes} িনিট আগে',
    groups: {
      date: 'তারিখ',
      country_id: 'দেশ',
      program_id: 'প্রোগ্রাম',
      user_subaccount_id: 'ট্র্যাকলিঙ্ক',
      source_id: 'উৎস',
      landing_id: 'ল্যান্ডিং পেজ'
    },
    stats: {
      columns: {
        name: 'গোষ্ঠী 1',
        p1: 'গোষ্ঠী 2',
        p2: 'গোষ্ঠী 3',
        p3: 'গোষ্ঠী 4',
        p4: 'গোষ্ঠী 5',
        uniqs: 'ইউনিক',
        visits: 'ভিজিট',
        tb: 'ট্রাফিক ফিরে আসা ',
        cr: 'CR',
        epc: 'EPC',
        ecpm: 'eCPM',
        rec: 'অনুমোদিত',
        wai: 'মুলতুবী',
        rej: 'অস্বীকার',
        all_l: 'মোট',
        rec_s: 'অনুমোদিত',
        wai_s: 'মুলতুবী',
        rej_s: 'অস্বীকার',
        all_s: 'মোট'
      },
      groups: {
        percent: 'রূপান্তর শতাংশ',
        conversion: 'কনভার্সন',
        profit: 'মুনাফা'
      }
    },
    datepicker: {
      applyLabel: 'জমা দিন',
      cancelLabel: 'বাতিল',
      customRangeLabel: 'আপনার সময়কাল',
      daysOfWeek: {
        sunday: 'রবিবার',
        monday: 'সোমবার',
        tuestay: 'মঙ্গলবার',
        wednesday: 'বুধবার',
        thursday: 'বৃহস্পতিবার',
        friday: 'শুক্রবার',
        saturday: 'শনিবার'
      },
      monthNames: {
        january: 'জানুয়ারী',
        february: 'ফেব্রুয়ারি',
        march: 'মার্চ',
        april: 'এপ্রিল',
        may: 'মে',
        june: 'জুন',
        july: 'জুলাই',
        august: 'অগাস্ট',
        september: 'সেপ্টেম্বর',
        october: 'অক্টোবর',
        november: 'নভেম্বর',
        december: 'ডিসেম্বর'
      },
      ranges: {
        today: 'আজ',
        yesterday: 'গতকাল',
        last_seven_days: 'শেষ ৭ দিন',
        last_thirty_days: 'শেষ ৩০ দিন',
        this_month: 'বর্তমান মাস',
        last_month: 'পূর্ববর্তী মাস'
      }
    },
    download_selected: 'ডাউনলোড নির্বাচিত',
    select_column: 'ডান ক্লিক করে কলাম নির্বাচন করুন',
    try_postback: {
      url_requested: 'অনুরোধ করা url',
      params_passed: 'অতীত পরামিতি',
      unknown_params: 'Unknown params',
      response_received: 'সাড়া পেয়েছি',
      result: 'ফলাফল'
    },
    transactions: {
      choose_type: 'Choose the transaction type'
    }
  },
  ru: {
    save: 'Сохранить',
    secondsToNextAttempts: 'Отправить ещё раз через {seconds} сек.',
    minutes_ago: '{minutes} мин. назад',
    groups: {
      date: 'Дата',
      country_id: 'Страна',
      program_id: 'Программа',
      user_subaccount_id: 'Ссылка',
      source_id: 'Источник',
      landing_id: 'Лендинг'
    },
    stats: {
      columns: {
        name: 'Группировка 1',
        p1: 'Группировка 2',
        p2: 'Группировка 3',
        p3: 'Группировка 4',
        p4: 'Группировка 5',
        uniqs: 'Уники',
        visits: 'Визиты',
        tb: 'Трафик back',
        cr: 'CR',
        epc: 'EPC',
        ecpm: 'eCPM',
        rec: 'Принято',
        wai: 'Ожидает',
        rej: 'Откл',
        all_l: 'Всего',
        rec_s: 'Принято',
        wai_s: 'Ожидает',
        rej_s: 'Откл',
        all_s: 'Всего'
      },
      groups: {
        percent: 'Коэффициенты',
        conversion: 'Конверт',
        profit: 'Доход'
      }
    },
    datepicker: {
      applyLabel: 'Применить',
      cancelLabel: 'Отмена',
      customRangeLabel: 'Свой период',
      daysOfWeek: {
        sunday: 'Вс',
        monday: 'Пн',
        tuestay: 'Вт',
        wednesday: 'Ср',
        thursday: 'Чт',
        friday: 'Пт',
        saturday: 'Сб'
      },
      monthNames: {
        january: 'Январь',
        february: 'Февраль',
        march: 'Март',
        april: 'Апрель',
        may: 'Май',
        june: 'Июнь',
        july: 'Июль',
        august: 'Август',
        september: 'Сентябрь',
        october: 'Октябрь',
        november: 'Ноябрь',
        december: 'Декабрь'
      },
      ranges: {
        today: 'Сегодня',
        yesterday: 'Вчера',
        last_seven_days: 'Последние 7 дней',
        last_thirty_days: 'Последние 30 дней',
        this_month: 'Этот месяц',
        last_month: 'Предыдущий месяц'
      }
    },
    download_selected: 'Скачать выбранные',
    select_column: 'Выберите колонки кликом правой клавиши мыши',
    try_postback: {
      url_requested: 'Запрошенный URL',
      params_passed: 'Переданные параметры',
      unknown_params: 'Неизвестные параметры',
      response_received: 'Полученный ответ',
      result: 'Результат'
    },
    transactions: {
      choose_type: 'Выбрать тип транзакции'
    }
  },
  es: {
    save: 'Guardar',
    secondsToNextAttempts: 'Enviar de nuevo en {seconds} segundos.',
    minutes_ago: 'hace {minutes} min.',
    groups: {
      date: 'Fecha',
      country_id: 'País',
      program_id: 'Programa',
      user_subaccount_id: 'Tracklink',
      source_id: 'Fuente',
      landing_id: 'Página de destino'
    },
    stats: {
      columns: {
        name: 'El conjunto 1',
        p1: 'El conjunto 2',
        p2: 'El conjunto 3',
        p3: 'El conjunto 4',
        p4: 'El conjunto 5',
        uniqs: 'Únicos',
        visits: 'Visitas',
        tb: 'Tráfico back',
        cr: 'CR',
        epc: 'EPC',
        ecpm: 'eCPM',
        rec: 'Pagada',
        wai: 'Pendiente',
        rej: 'Negado',
        all_l: 'Total',
        rec_s: 'Pagada',
        wai_s: 'Pendiente',
        rej_s: 'Negado',
        all_s: 'Total'
      },
      groups: {
        percent: 'Porcentaje de conversió',
        conversion: 'Conversiones',
        profit: 'Beneficio'
      }
    },
    datepicker: {
      applyLabel: 'Enviar',
      cancelLabel: 'Cancelar',
      customRangeLabel: 'Tu periodo',
      daysOfWeek: {
        sunday: 'Do',
        monday: 'Lu',
        tuestay: 'Ma',
        wednesday: 'Mi',
        thursday: 'Ju',
        friday: 'Vi',
        saturday: 'Sa'
      },
      monthNames: {
        january: 'Enero',
        february: 'Febrero',
        march: 'Marzo',
        april: 'Abril',
        may: 'Mayo',
        june: 'Junio',
        july: 'Julio',
        august: 'Agosto',
        september: 'Septiembre',
        october: 'Octubre',
        november: 'Noviembre',
        december: 'Diciembre'
      },
      ranges: {
        today: 'Hoy',
        yesterday: 'Ayer',
        last_seven_days: 'Últimos 7 días',
        last_thirty_days: 'Últimos 30 días',
        this_month: 'Mes actual',
        last_month: 'Mes anterior'
      }
    },
    download_selected: 'Descargar seleccionadas',
    select_column: 'Seleccione las columnas con clic derecho',
    try_postback: {
      url_requested: 'URL solicitada',
      params_passed: 'Parámetros pasados',
      unknown_params: 'Unknown params',
      response_received: 'Respuesta recibida',
      result: 'Resultado'
    },
    transactions: {
      choose_type: 'Elija el tipo de transacción'
    }
  },
  fr: {
    save: 'sauvegarder',
    secondsToNextAttempts: 'Renvoyez dans {seconds} s.',
    minutes_ago: '{minutes} min. ago',
    groups: {
      date: 'Date',
      country_id: 'Country',
      program_id: 'Program',
      user_subaccount_id: 'Tracklink',
      source_id: 'Source',
      landing_id: 'Landing page'
    },
    stats: {
      columns: {
        name: 'Regroupement 1',
        p1: 'Regroupement 2',
        p2: 'Regroupement 3',
        p3: 'Regroupement 4',
        p4: 'Regroupement 5',
        uniqs: 'Uniques',
        visits: 'Visits',
        tb: 'Trafic retour',
        cr: 'CR',
        epc: 'EPC',
        ecpm: 'eCPM',
        rec: 'Approuvé',
        wai: 'en attente',
        rej: 'refusé',
        all_l: 'Total',
        rec_s: 'Approuvé',
        wai_s: 'en attente',
        rej_s: 'refusé',
        all_s: 'Total'
      },
      groups: {
        percent: 'Pourcentage de conversion',
        conversion: 'Conversions',
        profit: 'Profit'
      }
    },
    datepicker: {
      applyLabel: 'Submit',
      cancelLabel: 'Cancel',
      customRangeLabel: 'Votre période',
      daysOfWeek: {
        sunday: 'Su',
        monday: 'Mo',
        tuestay: 'Tu',
        wednesday: 'We',
        thursday: 'Th',
        friday: 'Fr',
        saturday: 'Sa'
      },
      monthNames: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December'
      },
      ranges: {
        today: "Aujourd'hui",
        yesterday: 'Hier',
        last_seven_days: 'Les 7 derniers jours',
        last_thirty_days: 'Les 30 derniers jours',
        this_month: 'Mois en cours',
        last_month: 'Le mois précédent'
      }
    },
    download_selected: 'Télécharger les données sélectionnées',
    select_column: 'Veuillez sélectionner les colonnes par un clic droit',
    try_postback: {
      url_requested: 'URL demandée',
      params_passed: 'Paramètres passés',
      unknown_params: 'Unknown params',
      response_received: 'Réponse reçue',
      result: 'Result'
    },
    transactions: {
      choose_type: 'Choose the transaction type'
    }
  },
  ar: {
    save: ' حفظ ',
    secondsToNextAttempts: 'ثانية{seconds} أرسل مرة أخرى بعد',
    minutes_ago: '{minutes} min. ago',
    groups: {
      date: 'التاريخ',
      country_id: 'Country',
      program_id: 'Program',
      user_subaccount_id: 'Tracklink',
      source_id: 'Source',
      landing_id: 'Landing page'
    },
    stats: {
      columns: {
        name: ' تجمع  1',
        p1: ' تجمع  2',
        p2: ' تجمع  3',
        p3: ' تجمع  4',
        p4: ' تجمع  5',
        uniqs: 'الوحدات',
        visits: 'الزيارات',
        tb: 'رجوع الترافيك',
        cr: 'CR',
        epc: 'EPC',
        ecpm: 'eCPM',
        rec: ' موافق عليه ',
        wai: ' قيد الانتظار ',
        rej: ' رفض ',
        all_l: ' المجموع ',
        rec_s: ' موافق عليه ',
        wai_s: ' قيد الانتظار ',
        rej_s: ' رفض ',
        all_s: ' المجموع '
      },
      groups: {
        percent: 'نسبة التحويل',
        conversion: ' التحويلات ',
        profit: ' ربح '
      }
    },
    datepicker: {
      applyLabel: 'Submit',
      cancelLabel: 'Cancel',
      customRangeLabel: 'اختر تاريخ اخر',
      daysOfWeek: {
        sunday: 'Su',
        monday: 'Mo',
        tuestay: 'Tu',
        wednesday: 'We',
        thursday: 'Th',
        friday: 'Fr',
        saturday: 'Sa'
      },
      monthNames: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December'
      },
      ranges: {
        today: 'اليوم',
        yesterday: 'الامس',
        last_seven_days: 'اخر 7 ايام',
        last_thirty_days: 'اخر 30 يو م',
        this_month: 'الشهر الحالي',
        last_month: 'الشهر الماضي'
      }
    },
    download_selected: 'تنزيل البيانات المحددة',
    select_column: 'يرجى تحديد الأعمدة بالنقر بزر الفأرة الأيمن',
    try_postback: {
      url_requested: 'عنوان الرابط المطلوب',
      params_passed: 'المعلمات تم تمريرها',
      unknown_params: 'Unknown params',
      response_received: 'تم تلقي الرد',
      result: 'Result'
    },
    transactions: {
      choose_type: 'Choose the transaction type'
    }
  },
};
