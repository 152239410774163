<script>
    import { mapState, mapActions }     from 'vuex'
    import TablePagination              from "../../../src/components/TablePagination/table_pagination"
    import TableFilter                  from "./transactions_table/components/TableFilter/table_filter"
    import SummaryTable                 from "./transactions_table/components/SummaryTable/summary_table"

    import './transactions_table/style.scss'

    export default {
      name: 'TransactionsTable',
      props: {
        translations:      { type: Object, required: true },
        transaction_types: { type: Array,  required: true }
      },
      components: { TablePagination, TableFilter, SummaryTable },
      computed: {
        ...mapState([
          'pageInfo',
          'transactions',
        ])
      },
      mounted() {
        this.bindHistoryEvents(this) /*[h]*/
      },
      methods: {
        ...mapActions(['fetchTransactions', 'updateHistory', 'bindHistoryEvents', 'filterTransactions']),
        onChangePage: function(page) {
          this.fetchTransactions({ page })
          this.updateHistory({ page })
        },
        onFilter: function(newFilters, pushState = true) {
          this.filterTransactions({ comp: this, newFilters, pushState })
        },
        emptySet: function() { return this.transactions.length === 0 },
        filterByOrderId: function(transaction_id) {
          this.$root.$emit('FilterTransactionType/reset')
          this.onFilter({ order_id: transaction_id, type_id: null, date_range: null })
        }
      }
    }
</script>

<template>
    <div>
        <div class="row">
            <div class="col-lg-6">
                <table-filter :submit="onFilter" :transaction_types="transaction_types"></table-filter>
            </div>
            <div class="col-lg-6">
                <summary-table :transaction_types="transaction_types"></summary-table>
            </div>
        </div>
        <table-pagination :page-info="pageInfo" :change="onChangePage"></table-pagination>
        <div style="min-height: 200px" class="table-responsive">
            <table class="table programs-index table-striped table-hover">
                <thead>
                    <th class="text-center">Lead ID</th>
                    <th class="text-center">Click {{ translations.ts }}</th>
                    <th class="text-center">{{ translations.type }}</th>
                    <th class="text-center">{{ translations.ts }}</th>
                    <th class="text-center">{{ translations.amount }}</th>
                    <th class="text-center">{{ translations.comment }}</th>
                </thead>
                <tbody>
                    <tr v-for="t in transactions" :key= "t.key">
                        <td><a class="lead-id" :data-order_id=t.order_id @click.prevent="filterByOrderId(t.id)">{{ t.order_id }}</a></td>
                        <td>{{ t.ts }}</td>
                        <td>{{ t.type }}</td>
                        <td>{{ t.created_at }}</td>
                        <td :class="t.css_class_amount"><strong>{{ t.amount_text }}</strong></td>
                        <td>{{ t.comment }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="empty-set" v-if="emptySet()">
                <strong>Empty</strong>
            </div>
        </div>
        <table-pagination :page-info="pageInfo" :change="onChangePage"></table-pagination>
    </div>
</template>