<script>
  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/vue-loading.css'
  export default {
    name: 'TableLoading',
    props: {
      active: {
        type:     Boolean,
        required: false,
        default:  false,
      }
    },
    components: { Loading }
  }
</script>

<template>
    <loading :active="active" :is-full-page="false" :width="30" />
</template>