import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueSelect from 'vue-select';
// import moment from 'moment/moment';
import $ from 'jquery';
import VueResource from 'vue-resource';
import daterangepicker from 'daterangepicker';
import moment from "moment";
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import './components/header_group'
import { DISPATCH_LOAD_SETTINGS } from './store/modules/columns/actions/types';
import { COMMIT_TOGGLE_OPEN } from './store/modules/columns/mutations/types';

// app/views/stats/index.html.erb

$(async function() {
  var statsElem = document.getElementById('statistics');

  if (!statsElem) return;

  var App, app, i18n, obj;

  App = {};
  App.cr = function(leads, clicks) {
    var cr;
    clicks = parseFloat(clicks);
    leads = parseFloat(leads);
    cr = 0;
    if (clicks > 0) {
      cr = ((leads / clicks) * 100).toFixed(2);
    }
    return cr;
  };
  App.epc = function(amount, clicks) {
    var epc;
    clicks = parseFloat(clicks);
    amount = parseFloat(amount);
    epc = 0;
    if (clicks > 0) {
      epc = (amount / clicks).toFixed(2);
    }
    return epc;
  };
  App.ecpm = function(amount, clicks) {
    var ecpm;
    clicks = parseFloat(clicks);
    amount = parseFloat(amount);
    ecpm = 0;
    if (clicks > 0) {
      ecpm = ((amount / clicks) * 1000).toFixed(2);
    }
    return ecpm;
  };
  if (statsElem) {
    Vue.component('v-select', VueSelect);
    Vue.use(VueResource);
    Vue.use(VueI18n);

    let locale = $('#statistics').data('locale')
    if (!window.i18n[locale]) locale = 'en';

    i18n = new VueI18n({
      locale: locale,
      messages: window.i18n
    });

    const { default: store } = await import(/* webpackChunkName: "statsStore" */ './store')
    await import(/* webpackChunkName: "statsColumnDisplaySettings" */ './components/column_display_settings')

    app = new Vue({
      el: '#statistics',
      i18n,
      store,
      data: {
        last_updated: null,
        filters: {
          date_range: null,
          device: 'both',
          group: {
            p1: '',
            p2: '',
            p3: '',
            p4: ''
          }
        },
        options: {
          countries: [],
          programs: [],
          subaccounts: [],
          delimiters: '; ,'.split(' ')
        },
        groups: {
          date: i18n.t("groups.date"),
          country_id: i18n.t("groups.country_id"),
          program_id: i18n.t("groups.program_id"),
          user_subaccount_id: i18n.t("groups.user_subaccount_id"),
          source_id: i18n.t("groups.source_id"),
          landing_id: i18n.t("groups.landing_id"),
          preland_id: 'Pre-landing',
          sub1_id: 'Sub 1',
          sub2_id: 'Sub 2',
          sub3_id: 'Sub 3',
          sub4_id: 'Sub 4',
          sub5_id: 'Sub 5',
          utm_source_id: 'UTM source',
          utm_medium_id: 'UTM medium',
          utm_term_id: 'UTM term',
          utm_content_id: 'UTM content',
          utm_campaign_id: 'UTM campaign'
        },
        headers: {},
        shows: {
          p1: false,
          p2: false,
          p3: false,
          p4: false
        },
        items: {},
        locals: [],
        old_items: {},
        group_by: 'date',
        order_by: 'date DESC',
        titles: {
          date: i18n.t("groups.date"),
          country_id: i18n.t("groups.country_id"),
          program_id: i18n.t("groups.program_id"),
          user_subaccount_id: i18n.t("groups.user_subaccount_id"),
          source_id: i18n.t("groups.source_id"),
          landing_id: i18n.t("groups.landing_id"),
          preland_id: 'Pre-landing',
          sub1_id: 'Sub 1',
          sub2_id: 'Sub 2',
          sub3_id: 'Sub 3',
          sub4_id: 'Sub 4',
          sub5_id: 'Sub 5',
          utm_source_id: 'UTM source',
          utm_medium_id: 'UTM medium',
          utm_term_id: 'UTM term',
          utm_content_id: 'UTM content',
          utm_campaign_id: 'UTM campaign'
        },
        locale: {
          format: 'DD.MM.YYYY',
          applyLabel: i18n.t("datepicker.applyLabel"),
          cancelLabel: i18n.t("datepicker.cancelLabel"),
          customRangeLabel: i18n.t("datepicker.customRangeLabel"),
          firstDay: 1,
          daysOfWeek: [i18n.t("datepicker.daysOfWeek.sunday"), i18n.t("datepicker.daysOfWeek.monday"), i18n.t("datepicker.daysOfWeek.tuestay"), i18n.t("datepicker.daysOfWeek.wednesday"), i18n.t("datepicker.daysOfWeek.thursday"), i18n.t("datepicker.daysOfWeek.friday"), i18n.t("datepicker.daysOfWeek.saturday")],
          monthNames: [i18n.t("datepicker.monthNames.january"), i18n.t("datepicker.monthNames.february"), i18n.t("datepicker.monthNames.march"), i18n.t("datepicker.monthNames.april"), i18n.t("datepicker.monthNames.may"), i18n.t("datepicker.monthNames.june"), i18n.t("datepicker.monthNames.july"), i18n.t("datepicker.monthNames.august"), i18n.t("datepicker.monthNames.september"), i18n.t("datepicker.monthNames.october"), i18n.t("datepicker.monthNames.november"), i18n.t("datepicker.monthNames.december")]
        },
        ranges: (
          //region ranges
          obj = {},
          obj["" + (i18n.t('datepicker.ranges.today'))] = [moment(), moment()],
          obj["" + (i18n.t('datepicker.ranges.yesterday'))] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          obj["" + (i18n.t('datepicker.ranges.last_seven_days'))] = [moment().subtract(6, 'days'), moment()],
          obj["" + (i18n.t('datepicker.ranges.last_thirty_days'))] = [moment().subtract(29, 'days'), moment()],
          obj[(i18n.t('datepicker.ranges.this_month')) + " "] = [moment().startOf('month'), moment().endOf('month')],
          obj["" + (i18n.t('datepicker.ranges.last_month'))] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
          obj
          //endregion
        ),
        // -- CSV --
        csvDelimiter:    ';',
        selectedColumns: {},
      },
      computed: {
        ...mapGetters('columns', ['displaySettings']),
        ...mapState('columns', { isColumnsOpen: 'open' }),
        csvFileName:   function() { return `paysale_${moment().format('YYYY_MM_DD_HHmmss')}.csv` },
        isAnySelected: function() { return Object.keys(this.selectedColumns).length > 0 },
        btnText:       function() { return this.isAnySelected ? i18n.t("download_selected") : i18n.t("select_column")},
      },
      mounted: function() {
        const cb = function(start, end) {
          $('#date_range').val(start.format('DD.MM.YYYY') + ' - ' + end.format('DD.MM.YYYY'));
        };
        const start = moment().subtract(6, 'days');
        const end = moment();

        const rangeElFormControl = $('#date_range');
        if(!rangeElFormControl.val()) {
          rangeElFormControl.val(start.format('DD.MM.YYYY') + ' - ' + end.format('DD.MM.YYYY'));
        }

        $('#date_range_group').daterangepicker({
          alwaysShowCalendars: true,
          linkedCalendars: false,
          opens: 'right',
          locale: this.locale,
          ranges: this.ranges,
          showDropdowns: true,
          startDate: start,
          endDate: end
        }, cb);
        this.headers[1] = this.groups.date;
        this.fetchData();
      },
      created: function() {
        this.loadColumnSettings()
        this.getCountries();
        this.getPrograms();
        this.getSubs();
      },
      methods: {
        ...mapActions('columns', { loadColumnSettings: DISPATCH_LOAD_SETTINGS }),
        ...mapMutations('columns', { 'handleOpenColumnSettings': COMMIT_TOGGLE_OPEN }),
        cr: function(leads, clicks) {
          return App.cr(leads, clicks);
        },
        epc: function(amount, clicks) {
          return App.epc(amount, clicks);
        },
        ecpm: function(amount, clicks) {
          return App.ecpm(amount, clicks);
        },
        prepareIds: function(values) {
          var temp_values;
          if (values === null || values === void 0) {
            return;
          }
          temp_values = [];
          values.map(function(value, key) {
            temp_values.push(value.id);
          });
          return temp_values;
        },
        getPrograms: function() {
          this.$http.get('/user/stats/programs').then((function(resp) {
            this.options.programs = resp.body;
          }), function(resp) {});
        },
        getSubs: function() {
          this.$http.get('/user/stats/user_subaccounts').then((function(resp) {
            this.options.subaccounts = resp.body;
          }), function(resp) {});
        },
        getCountries: function() {
          this.$http.get('/user/stats/countries').then((function(resp) {
            this.options.countries = resp.body;
          }), function(resp) {});
        },
        grouping: function(type) {
          this.group_by = type;
          if (type !== 'date') {
            this.order_by = '';
          }
          if (type === 'date') {
            this.order_by = 'date DESC';
          }
          this.fetchData();
        },
        fetchData: function() {
          if ($('#date_range').val() !== '') {
            this.filters.date_range = $('#date_range').val();
          }
          this.filters.country_id = this.prepareIds(this.filters.countries);
          this.filters.order_by = this.order_by;
          this.filters.program_id = this.prepareIds(this.filters.programs);
          this.filters.user_subaccount_id = this.prepareIds(this.filters.subaccounts);
          this.filters.group_by = this.group_by;
          this.$http.get('/user/stats/fetch?' + $.param(this.filters)).then((function(resp) {
            var items, last_up_min, last_up_sec, last_updated_ar, locals, self;
            self = this;
            items = {};
            locals = {};
            self.locals = resp.body;
            self.items = JSON.parse(JSON.stringify(resp.body));
            self.headers[1] = self.groups[self.group_by];
            last_updated_ar = [];
            if (self.items.length > 0) {
              self.locals = self.items.map(function(value) {
                last_updated_ar.push(Date.parse(value['last_updated']));
                // определимся с группировкой
                if (value['p1'] !== void 0) {
                  self.shows.p1 = true;
                  self.headers[2] = self.groups[self.filters.group.p1];
                } else {
                  self.shows.p1 = false;
                }
                if (value['p2'] !== void 0) {
                  self.shows.p2 = true;
                  self.headers[3] = self.groups[self.filters.group.p2];
                } else {
                  self.shows.p2 = false;
                }
                if (value['p3'] !== void 0) {
                  self.shows.p3 = true;
                  self.headers[4] = self.groups[self.filters.group.p3];
                } else {
                  self.shows.p3 = false;
                }
                if (value['p4'] !== void 0) {
                  self.shows.p4 = true;
                  self.headers[5] = self.groups[self.filters.group.p4];
                } else {
                  self.shows.p4 = false;
                }
                // допишем вычисляемые значения в каждый элемент
                value.cr   = self.cr(value.all_l_conv, value.uniqs_conv)
                value.epc  = self.epc(value.all_s_conv, value.uniqs_conv)
                value.ecpm = self.ecpm(value.all_s_conv, value.uniqs_conv)
                return value
              });

              last_up_sec = Math.max.apply(Math, last_updated_ar);
              last_up_min = parseInt(moment.duration(moment().diff(moment(last_up_sec))).asMinutes());
              if (last_up_min < 41) {
                self.last_updated = i18n.t('minutes_ago', {
                  minutes: last_up_min
                });
              }
            }
          }), function(resp) {});
        },
        sum: function(property) {
          var sum;
          sum = 0;
          this.locals.map(function(u) {
            sum += parseInt(u[property]);
          });
          return sum;
        },
        sum_cur: function(property) {
          var sum;
          sum = 0.0;
          this.locals.map(function(u) {
            sum = (parseFloat(sum) + parseFloat(u[property])).toFixed(2);
          });
          return parseFloat(sum).toFixed(2);
        },
        // -- считают значения в строке "Totals"
        totalFunctions_name:   () => 'Total',
        totalFunctions_uniqs:  function() { return this.sum('uniqs') },
        totalFunctions_visits: function() { return this.sum('visits') },
        totalFunctions_tb:     function() { return this.sum('tb') },
        totalFunctions_cr:     function() { return this.cr(this.sum('all_l_conv'), this.sum('uniqs_conv')) },
        totalFunctions_epc:    function() { return this.epc(this.sum_cur('all_s_conv'), this.sum('uniqs_conv')) },
        totalFunctions_ecpm:   function() { return this.ecpm(this.sum_cur('all_s_conv'), this.sum('uniqs_conv')) },
        totalFunctions_rec:    function() { return this.sum('rec') },
        totalFunctions_wai:    function() { return this.sum('wai') },
        totalFunctions_rej:    function() { return this.sum('rej') },
        totalFunctions_all_l:  function() { return this.sum('all_l') },
        totalFunctions_rec_s:  function() { return this.sum_cur('rec_s') },
        totalFunctions_wai_s:  function() { return this.sum_cur('wai_s') },
        totalFunctions_rej_s:  function() { return this.sum_cur('rej_s') },
        totalFunctions_all_s:  function() { return this.sum_cur('all_s') },
        // -- CSV --
        onExportColumn: function(event) {
          // слушаем правый клик мыши по <th> таблицы
          const $th    = $(event.target)
          const name   = $th.attr('name')    // ключ в объекте `item`
          if (this.selectedColumns[name] === undefined) {
            const pos    = +$th.attr('pos')  // позиция колонки в CSV
            const header = $th.text()        // "человеческий" заголовок колонки
            //console.log(name, pos, header)
            this.$set(this.selectedColumns, name, {pos, header, key: name})
          } else {
            this.$delete(this.selectedColumns, name)
          }
        },
        selClass: function(name) {
          // вернёт css-класс колонки "на экспорт"
          return { exp: !!this.selectedColumns[name], hidden: !this.displaySettings[name] }
        },
        downloadCSV: function() {
          let csvContent     = 'data:text/csv;charset=utf-8,'
          const sortedValues = arraySortBy(Object.values(this.selectedColumns), 'pos')
          const sortedKeys   = sortedValues.map(v => v.key)
          const rowHeaders   = sortedValues.map(v => v.header)
          csvContent        += this.prepareCsvRow(rowHeaders)
          csvContent        += this.innerContentCSV(sortedKeys)
          this.sendCSVFile(csvContent)
        },
        prepareCsvRow: function(items) {
          return items.map(v => `"${v}"`).join(this.csvDelimiter) + "\r\n"
        },
        innerContentCSV: function(sortedKeys) {
          let csvContent = this.handleLocals('', sortedKeys)
          const rowTotals = sortedKeys.map(key => {
            const totalHandler = this['totalFunctions_'+key]
            return totalHandler ? totalHandler() : ''
          })
          csvContent += this.prepareCsvRow(rowTotals)
          return csvContent
        },
        handleLocals: function(csvContent, sortedKeys) {
          let rowItems
          this.locals.forEach(item => {
            rowItems = sortedKeys.map(key => item[key])
            rowItems = this.prepareCsvRow(rowItems)
            csvContent += rowItems
          })
          return csvContent
        },
        sendCSVFile: function(content) {
          const link = document.createElement("a")
          link.setAttribute("href", encodeURI(content))
          link.setAttribute("download", this.csvFileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    });
  }
});

