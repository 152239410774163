<script>
    import {settings}     from './settings'
    import getParamByName from "../../../../src/utils/get_param_by_name"
    import {DateRange}    from "./date_range"

    const NAME = 'FilterDateRange'

    export default {
      name: NAME,
      props: {
        value: {
          type:       String,
          required:   false,
          default:    null,
        }
      },
      mounted() {
        this.parseQuery()
        this.setupPicker()
        this.$root.$on('popstate', this.parseQuery) /*[h]*/
        this.$root.$on(`${NAME}/reset`, () => this.setDefaultValue())
      },
      computed: {
        innerValue: {
          get()  { return this.value },
          set(v) { this.$emit('input', v) }
        },
        localizedValue: {
          get()  { return DateRange.localize(this.innerValue, settings.format) },
          set(v) { this.innerValue = DateRange.normalize(v, settings.format) }
        },
      },
      methods: {
        parseQuery() {
          const incoming_value = getParamByName('date_range')
          let xx
          if (incoming_value && (xx = DateRange.localize(incoming_value, settings.format))) {
            this.$store.dispatch('filters/initQueryParsedFilter', { name: 'date_range', value: incoming_value })
            this.localizedValue = xx
          } else {
            this.setDefaultValue()
          }
        },
        setupPicker() {
          const that = this
          this.$input = $(this.$el).find('input')

          this.$input.daterangepicker({
            alwaysShowCalendars: true,
            linkedCalendars:     false,
            opens:               'right',
            locale:              settings.locale,
            ranges:              settings.ranges,
            showDropdowns:       true,
            format:              settings.format
          })

          this.$input.on('apply.daterangepicker', function(ev, picker) {
            that.localizedValue = DateRange.range(picker.startDate, picker.endDate, settings.format)
          })
        },
        setDefaultValue() {
          this.$store.dispatch('filters/initQueryParsedFilter', { name: 'date_range', value: DateRange.normalize(settings.defaultValue(), settings.format) })
          this.localizedValue = settings.defaultValue()
        }
      }
    }
</script>

<template>
    <div class="input-group" id="date_range_group">
        <div class="input-group-prepend"><span class="input-group-text"><i class="icon icon-calendar"></i></span></div>
        <input type="text" data-position="right" class="form-control" v-model="localizedValue" autocomplete="off" />
    </div>
</template>