import * as types from './mutation_types'
import Vue        from "vue"

export default {
  [types.INIT_QUERY_PARSED_FILTER](state, { name, value }) {
    // Object.assign(state, {[name]:value})
    Vue.set(state, name, value)
  },

  [types.UPDATE_FILTERS](state, newFilters) {
    Object.assign(state, newFilters)
  },

  [types.UPDATE_FILTER](state, { name, value }) {
    Vue.set(state, name, value)
  }
}
