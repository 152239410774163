export default class WalletDatum {
  constructor() {
    this.init()
  }

  init() {
    this.init_latin_only_allowed()
    this.init_hint()
    this.handle_validation_error()
  }

  // только латинские символы и цифры
  init_latin_only_allowed() {
    for (let i = 0; i < WalletDatum.fields.length; i++)
      $(this.field(WalletDatum.fields[i])).on('input', this.oninput.bind(this))
  }

  // подсказку покажем только когда поле в фокусе
  init_hint() {
    let $field
    for (let i = 0; i < WalletDatum.fields.length; i++) {
      $field = $(this.field(WalletDatum.fields[i]))
      $field.on('focus', this.onfocus.bind(this))
      $field.on('blur', this.onblur.bind(this))
    }
  }

  field = (fname) => WalletDatum.prefix + fname
  $hint = (fnode) => $(fnode).closest('div').find('small.form-text')

  oninput = (e) => e.target.value = e.target.value.replace(WalletDatum.LATIN, '')
  onfocus = (e) => this.$hint(e.target).addClass('enabled')
  onblur  = (e) => this.$hint(e.target).removeClass('enabled')

  // С помощью simple_fields_for "из коробки" не получилось отобразить ошибку валидации наличия
  // вложенной ассоциации `user.profile.wallet_datum.address_detail`
  // по-этому делаем это с помощью js тут
  handle_validation_error() {
    const $div = $('.address_country')
    const errs = $div.data('errors')
    let addr   = errs['profile.wallet_datum.address_detail']

    if (addr && addr[0] !== null) {
      const $input = $('#user_profile_attributes_wallet_datum_attributes_address_detail_attributes___city')
      $input.addClass('is-invalid')
      $(`<div class="invalid-feedback">${addr[0]}</div>`).insertAfter($input)
    }
  }
}

WalletDatum.LATIN  = /[^0-9a-zA-Z,.,.\-\s'"«»„“]/g
WalletDatum.prefix = '#user_profile_attributes_wallet_datum_attributes_'
WalletDatum.fields = [
  '_name',
  '_surname',
  'address_detail_attributes___city',
  'address_detail_attributes___address'
]
