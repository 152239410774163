
$(function () {
  $('[data-toggle="tooltip-auto"]').tooltip({
    animation: true,
    placement: 'top',
    trigger: 'hover'
  });

  $(".fancybox").fancybox();
});
