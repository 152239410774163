import * as types     from './mutation_types'
import countries      from '../api/countries'
import activate_offer from '../api/activate_offer'
import update_clicks  from '../api/update_clicks'
import update_landing from '../api/update_landing'

export default {
  initialize({ dispatch, state }) {
    dispatch('pageInfo/initPageInfo')
    dispatch('fetchCountries', { page: state.pageInfo.page })
  },
  fetchCountries({ dispatch, commit, state }, params) {
    commit(types.REQUEST_COUNTRIES)
    const newParams = !params ? { page: state.pageInfo.page } : params
    return countries(state)
         .query({ per_page: 5, ...newParams, ...state.filters })
         .then(
             ({ data, headers }) => {
              dispatch('pageInfo/updatePageInfo', headers)
              commit(types.REQUEST_COUNTRIES_SUCCESS, data)
             },
             () => { commit(types.REQUEST_COUNTRIES_FAIL) }
         )
  },
  activateOffer({ dispatch, commit, state}, { url, country_id, device }) {
    commit(types.ACTIVATE_PROGRAM)
    return activate_offer(url)
        .save({ country_id: country_id, device: device })
        .then(
            ({ data }) => { commit(types.ACTIVATE_PROGRAM_SUCCESS, data) },
            () => { commit(types.ACTIVATE_PROGRAM_FAIL) }
        )
  },
  makeOfferJustActivated({ commit }, data) {
    commit(types.MAKE_OFFER_JUST_ACTIVATED, data)
  },
  updateClicks({ dispatch, commit, state}, { country_id, device, program_id, clicks }) {
    commit(types.UPDATE_CLICKS, { country_id, device, program_id })
    return update_clicks(state)
        .save({
          country_id: country_id,
          device:     device,
          program_id: program_id,
          clicks:     clicks,
        })
        .then(
            ({ data }) => { commit(types.UPDATE_CLICKS_SUCCESS, data) },
            ({body}) => {
              commit(types.UPDATE_CLICKS_FAIL, { country_id, device, program_id, clicks, errors: body.errors })
            }
        )
  },
  updateLanding({ dispatch, commit, state }, { country_id, device, program_id, landing_id }) {
    commit(types.UPDATE_LANDING, { country_id, device, program_id })
    return update_landing(state)
        .save({
          country_id: country_id,
          device:     device,
          landing_id: landing_id,
          program_id: program_id,
        })
        .then(
            ({ data }) => { commit(types.UPDATE_LANDING_SUCCESS, data) },
            ({ body, statusText }) => {
              commit(types.UPDATE_LANDING_FAIL, { country_id, device, program_id, errors: body.errors || [[statusText]] })
            }
        )
  }
}