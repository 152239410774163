// button_progress_overlay.scss

// в jquery-dom-узел $node положит оверлей с анимацией процесса
//
// Markup example:
//
//   .form-group
//     .position-relative.d-inline-block.primary
//       button.btn.btn-primary type="submit" = t('.submit')
//
//
export default class ButtonProgressOverlay {
  static show($button) {
    const $parent  = $button.parent()
    const height   = $button.outerHeight() - 14
    const $overlay = $(ButtonProgressOverlay.MARKUP)

    // оверлей по высоте подгоним под высоту кнопки
    const $inner = $overlay.find('> div')
    $inner.css('height', height+'px')
    $inner.css('width', height+'px')

    if ($parent.css('position', 'static')) $parent.css('position', 'relative')
    $parent.append($overlay)
    $button.prop('disabled', true)
  }

  static hide($button) {
    const $parent = $button.parent()
    $parent.find('.button_progress_overlay').remove()
    $button.prop('disabled', false)
  }
}

ButtonProgressOverlay.MARKUP = `
  <div class="button_progress_overlay">
    <div role="progressbar">
      <svg viewBox="22 22 44 44">
        <circle class="circle" cx="44" cy="44" r="20.2" fill="none" stroke-width="3.6"></circle>
      </svg>
    </div>
  </div>
`;
