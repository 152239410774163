import Vue               from 'vue'
import show_simple_modal from "../../src/components/show_simple_modal"

//
// Форма регистрации рекла
//

export const init_adv_form = () => {
  new Vue({
    el:   '#new_user',
    data: {
      form: {
        another_cpa: ''
      }
    },
    created: function() {

    },
    mounted: function() {
      this.fix_main_markup()
      this.init_form()
    },
    updated: function () {

    },
    methods: {
      fix_main_markup: function() {
        // форма "распухла" и вылезла за рамки, корректируем вёрстку в динамике
        $('div#primary').removeClass('align-items-center').find('> div.container > div.row').css('margin', '10rem 0')
      },
      init_form: function() {
        // после успешного сабмита формы юзер увидит модальное окно, закрыв которое он попадёт на goto_url
        $('form#new_user').init_remote({
          on_success: ({message, header, close, goto_url}) => show_simple_modal(message, header, close, () => window.location.href = goto_url)
        })
      }
    }
  })
}
