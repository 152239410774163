<script>

  import { mapState }   from 'vuex'
  import getParamByName from "../../../../../../../../src/utils/get_param_by_name"

  const NAME = 'FilterOrderId'

  export default {
    name: NAME,
    props: {
      value/* transaction.id */: {
        type:       String,
        required:   false,
        default:    null,
      },
    },
    mounted() {
      this.parseQuery()
      this.$root.$on('popstate', this.parseQuery) /*[h]*/
    },
    computed: {
      ...mapState(['filters', 'transactions', 'transactionsFetchInProgress'])
    },
    methods: {
      parseQuery() {
        const incoming_order_id = getParamByName('order_id')
        if (incoming_order_id == undefined && this.filters.order_id != undefined) {
          this.$store.dispatch('filters/updateFilters', { order_id: null })
        }
      },
      find(transaction_id) {
        let tr = this.transactions.find(x => x.id == transaction_id)
        return tr && tr.order_id
      },
      keyhandler(e) {
        e.preventDefault()
      }
    }
  }
</script>
<template>
  <div :class="transactionsFetchInProgress">
      <input type="text"
             class="form-control"
             :value="find(filters.order_id)"
             autocomplete="off"
             name="by_lead_id"
             placeholder="Click on «Lead id» value in the list to search"
             v-on:keydown="keyhandler"
      />
  </div>
</template>