import AbstractController   from "./abstract_controller"
import toastr                from "toastr"

const initRemoteForm = function() {
  $('form#validate_simple_source').init_remote({
    on_error: (data) => {
      // console.log(data)
      const messages = dig(data, ['errors', 'messages'])
      if (messages) {
        messages.split(';').forEach(message => toastr['error'](message))
      }
    },
    on_success: (data) => {
      // console.log(data)
      const notice = dig(data, ['notice'])
      if (notice) {
        toastr['success'](notice)
      }
      const warnings = dig(data, ['warnings', 0])
      // console.log(warnings)
      if (warnings) {
        warnings.split(';').forEach(message => toastr['warning'](message))
      }
    }
  })
}

const initImposerInput = function() {
  const $imposer = $('#imposer')
  const $archive = $('#archive')

  $archive.on('change', function() {
    const val = $archive.val().split('\\').pop()
    $imposer.val(val)
  })

  $imposer.on('click', function () {
    $archive.trigger('click')
  })
}

export default class extends AbstractController {
  static targets = [ "output" ]

  index = () => {
    initRemoteForm()
    initImposerInput()
  }
}
