/**
 * This is basically checking to see if either the top or bottom
 * bound is independently in the viewport. The opposite end may
 * be outside, but as long as one end is in, it's "visible" at
 * least partially.
 *
 * @param el
 * @returns {boolean}
 */
export const inView = function(el, offset = 0) {
  const bounding = el.getBoundingClientRect()
  // console.log(bounding.top, bounding.bottom, (window.innerHeight || document.documentElement.clientHeight))
  return (0 < bounding.top-offset && bounding.top < (window.innerHeight || document.documentElement.clientHeight)) ||
      (0 < bounding.bottom-offset && bounding.bottom < (window.innerHeight || document.documentElement.clientHeight))

}