import Vue                            from 'vue'
import {vselect_init, vselect_update} from "../../src/components/v_select"
import VueSelect                      from 'vue-select'

//
// Форма создания смартлинка:
//   * заменит стандартные <select> в форме на VueSelect
//   * сделает форму remote
//

export const init_smartlink_form = (view_object) => {
  // console.log('init_smartlink_form ' + Math.random())
  Vue.component('v-select', VueSelect)

  new Vue({
    el:   '.container#new_smartlink',
    data: {
      category: '',
      options: { // of {label,id}
        categories: []
      }
    },
    created: function () {

    },
    mounted: function () {
      this.init_form()
      this.vselect_mounted()
    },
    updated: function () {
      this.vselect_updated()
    },
    methods: {
      init_form: function () {
        $('form#new').init_remote()
      },
      vselect_updated: function() {
        vselect_update('.v-select__category', this.category.id)
      },
      vselect_mounted: function() {
        vselect_init('.v-select__category', this.options.categories, 'category', this)
      }
    }
  })

}
