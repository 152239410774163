<script>
  import { BLink } from 'bootstrap-vue'

  export default {
    components: {
      BLink,
    },
    props: {
      target: {
        type: String,
        required: false,
        default: null,
      },
    },
  };
</script>
<template>
    <b-link v-bind="$attrs" :target="target" class="gl-link" v-on="$listeners">
        <slot></slot>
    </b-link>
</template>
