(function() {
    "use strict";

    $(document).on('DOMContentLoaded', function () {
        setTimeout(function() {
          $('.select2-container').remove()
          $('.select2').select2();
        }, 555)
    })
}());
