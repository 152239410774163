import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const $js        = $('.js#controller')  // чтобы контроллер начал работать, в DOM должен присутствовать этот узел
    this.view_object = $js.data('vo')       // данные, которые передала рельса
    this.action      = $js.data('action')   // rails controller action name = js controller method name

    if (this.action !== undefined) {
      if (typeof this[this.action] === 'function') {
        this[this.action]()
      }
    }
  }
}
