import {init_common_form}                 from "./edit_subaccount/common_form"
import {init_postbacks_form}              from "./edit_subaccount/postbacks_form"
import {init_save_landing_rotation_form}  from "./edit_subaccount/save_landing_rotation_form"
import {init_try_postback_feature}        from "./edit_subaccount/try_postback_url"
import {tabs_init}                        from "../registrations/tabs"

export const init_edit_subaccount = (view_object) => {
  init_common_form(view_object)
  init_save_landing_rotation_form(view_object)
  init_try_postback_feature(view_object)
  init_postbacks_form(view_object)
  tabs_init()
}