window.dig = (obj, keys) =>
  keys.reduce(
      (xs, x) => (xs && xs[x] !== null && xs[x] !== undefined ? xs[x] : null),
      obj
  );
/*
EXAMPLES
let index = 2;
const data = {
  foo: {
    foz: [1, 2, 3],
    bar: {
      baz: ['a', 'b', 'c']
    }
  }
};
dig(data, ['foo', 'foz', index]); // get 3
dig(data, ['foo', 'bar', 'baz', 8, 'foz']); // null
*/