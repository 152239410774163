// /app/views/shared/_postback_inputs.html.slim

import Vue                      from 'vue'
import VueI18n                  from 'vue-i18n'
import VueResource              from 'vue-resource'
import ButtonProgressOverlay    from "./button_progress_overlay"
import show_simple_modal        from "./show_simple_modal"

export const try_postback_component = function({el, locale, try_postback_url, spam_click_threshold}) {

  Vue.use(VueI18n)
  Vue.use(VueResource)
  if (!window.i18n[locale]) locale = 'en'
  const i18n = new VueI18n({ locale, messages: window.i18n })

  new Vue({
    i18n,
    el,
    data: {
      loading:  false,
      resource: Vue.resource(try_postback_url),
    },
    created() {},
    mounted() {},
    watch:    {},
    computed: {
      isDisabled() {
        return this.loading
      },
    },
    methods: {
      onClickTryBtn(e) {
        e.preventDefault()

        const $btn = $(e.target)
        const that = this

        this.displayProgress($btn)
        setTimeout(function() { that.sendTryRequest($btn) }, spam_click_threshold)
      },
      sendTryRequest($btn) {
        const status = $btn.data('status')
        const url    = $btn.closest('.input-group').find('input').val()

        this.resource.save({status, url}).then(
            (data) => {
              this.hideProgress($btn)
              this.displayResponseModal(data)
            },
            () => {
              this.hideProgress($btn)
            }
        )
      },
      displayProgress($clickedBtn) {
        this.loading = true
        ButtonProgressOverlay.show($clickedBtn)
        $(this.$el).find('.form-control').prop('disabled', true)
      },
      hideProgress($clickedBtn) {
        this.loading = false
        ButtonProgressOverlay.hide($clickedBtn)
        $(this.$el).find('.form-control').prop('disabled', false)
      },
      displayResponseModal(data) {
        let message = ''

        const received            = dig(data, ['body', 'result', 'received'])
        const url_sent            = dig(data, ['body', 'result', 'url_sent'])
        const params_list         = dig(data, ['body', 'result', 'params_list'])
        const unknown_params_list = dig(data, ['body', 'result', 'unknown_params_list'])

        if (url_sent !== null) {
          message += `<h5>${i18n.t("try_postback.url_requested")}:</h5>`
          message += `<pre class="try_postback_response url">${url_sent}</pre>`
        }

        if (params_list !== null) {
          message += `<h5>${i18n.t("try_postback.params_passed")}:</h5>`
          message += `<pre class="try_postback_response">${params_list}</pre>`
        }

        if (unknown_params_list !== null && unknown_params_list.length > 0) {
          message += `<h5>${i18n.t("try_postback.unknown_params")}:</h5>`
          message += `<pre class="try_postback_response unknown_params_list">${unknown_params_list}</pre>`
        }

        if (received !== null) {
          message += `<h5>${i18n.t("try_postback.response_received")}:</h5>`
          message += `<pre class="try_postback_response received">${received}</pre>`
        }

        if (received === null && url_sent === null && params_list === null && unknown_params_list === null) {
          message += `<h5>${i18n.t("try_postback.response_received")}:</h5>`
          message += `<pre class="try_postback_response received">${data.bodyText}</pre>`
        }

        show_simple_modal(message, i18n.t("try_postback.result"), 'close', () => {})
      }
    }
  })
}