(function () {
  "use strict"

  $(document).on('DOMContentLoaded', function () {
    const $insts = $('.auto2')

    for (let i = 0; i < $insts.length; i++) {
      let inst  = $insts[i]
      let $inst = $(inst)
      let list  = $inst.data('list')

      if ($inst.hasClass('xdsoft_input')) {
        continue
      }

      $inst.autocomplete({ source: [list] })
    }
  })

}())
