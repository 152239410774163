<script>

import range                        from 'lodash/range'
import debounce                     from 'lodash/debounce'
import isFunction                   from 'lodash/isFunction'
import Link                         from './components/Link/link.vue'
import Breakpoints, { breakpoints } from '../../utils/breakpoints'

const pageRange = (from, to) => range(from, to + 1, 1)

export default {
  name: 'Pagination',
  components: { Link },
  model: {
    prop:  'value',
    event: 'input',
  },
  props: {
    // core
    value: {
      type:       Number,
      required:   false,
      default:    1,
      validator:  x => x > 0,
    },
    perPage: {
      type:       Number,
      required:   false,
      default:    20,
      validator:  x => x > 0,
    },
    totalItems: {
      type:       Number,
      required:   false,
      default:    0,
    },
    prevPage: {
      type:       Number,
      required:   false,
      default:    null,
    },
    nextPage: {
      type:       Number,
      required:   false,
      default:    null,
    },
    // misc
    disabled: {
      type:       Boolean,
      required:   false,
      default:    false,
    },
    limits: {
      type:       Object,
      required:   false,
      default:    () => ({
        xs:       0,
        sm:       3,
        md:       9,
        default:  9,
      }),
      validator: value => {
        const missingSizes = Object.keys(breakpoints).filter(size => !value[size]).length;
        return missingSizes === 0 ? true : value.default;
      },
    },
    linkGen: {
      type:       Function,
      required:   false,
      default:    null,
    },
    // labels
    labelPage: {
      type:       Function,
      required:   false,
      default:    page => `Go to page ${page}`,
    },
    ellipsisText: {
      type:       String,
      required:   false,
      default:    '…',
    },
  },
  data() {
    return {
      breakpoint: Breakpoints.getBreakpointSize(),
      minTotalPagesToCollapse: 4,
    };
  },
  computed: {
    visibleItems() {
      let items = []

      if (!this.isCompactPagination) {
          let firstPage = this.shouldCollapseLeftSide ? this.value - this.maxAdjacentPages : 1
          firstPage = Math.min(firstPage, this.totalPages - 1)
          let lastPage = this.shouldCollapseRightSide
              ? this.value + this.maxAdjacentPages
              : this.totalPages
          lastPage = Math.max(lastPage, 2)

          items = pageRange(firstPage, lastPage, 1).map(page => this.getPageItem(page))

          if (this.shouldCollapseLeftSide) {
            items.splice(
                0,
                0,
                this.getPageItem(1, this.labelFirstPage),
                this.getEllipsisItem('left')
            );
          }

          if (this.shouldCollapseRightSide) {
            items.push(
                this.getEllipsisItem('right'),
                this.getPageItem(this.totalPages, this.labelLastPage)
            );
          }
      }

      return items
    },
    isCompactPagination() {
      return Boolean(!this.totalItems && (this.prevPage || this.nextPage))
    },
    shouldCollapseLeftSide() {
      const diff = this.value - this.maxAdjacentPages
      return (
          diff >= this.maxAdjacentPages && diff > 3 && this.totalPages > this.minTotalPagesToCollapse
      )
    },
    shouldCollapseRightSide() {
      const diff = this.totalPages - 2 - this.value
      return diff > this.maxAdjacentPages && this.totalPages > this.minTotalPagesToCollapse
    },
    maxAdjacentPages() {
      return Math.max(Math.ceil((this.paginationLimit - 1) / 2), 0)
    },
    paginationLimit() {
      return typeof this.limits[this.breakpoint] !== 'undefined'
          ? this.limits[this.breakpoint]
          : this.limits.default
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage)
    },
    isLinkBased() {
      return isFunction(this.linkGen)
    },
  },
  created() {
    window.addEventListener('resize', debounce(this.setBreakpoint, 200))
  },
  beforeDestroy() {
    window.removeEventListener('resize', debounce(this.setBreakpoint, 200))
  },
  methods: {
    getPageItem(page, label = null) {
      const commonAttrs = {
        'aria-label': label || this.labelPage(page),
        href: '#',
        class: [],
      }

      const isActivePage = page === this.value
      const isDisabled   = this.pageIsDisabled(page)
      const attrs        = Object.assign({}, commonAttrs)
      const listeners    = {}

      // // if (this.isLinkBased)

      listeners.click = e => this.handleClick(e, page)

      return {
        content:    page,
        component:  Link,
        disabled:   isDisabled,
        active:     isActivePage,
        key:        `page_${page}`,
        slot:       'page-number',
        slotData: {
          page:     page,
          active:   isActivePage,
          disabled: isDisabled,
        },
        attrs:      attrs,
        listeners:  listeners,
      };
    },
    pageIsDisabled(page) {
      return (
          this.disabled ||
          page < 1 ||
          (this.isCompactPagination && page > this.value && !this.nextPage) ||
          (!this.isCompactPagination && page > this.totalPages)
      );
    },
    setBreakpoint() {
      this.breakpoint = Breakpoints.getBreakpointSize()
    },
    getEllipsisItem(side) {
      return {
        content: this.ellipsisText,
        key: `ellipsis_${side}`,
        slot: `ellipsis-${side}`,
        component: 'span',
        disabled: true,
      };
    },
    handleClick(event, value) {
      if (!this.isLinkBased) {
        event.preventDefault()
        this.$emit('input', value)
      }
    },
    isSingleTotalPages() {
      return this.totalPages <= 1
    }
  }
}
</script>

<template>
    <nav>
        <ul class="pagination" :class = "{ opacity0: isSingleTotalPages() }">
            <li
              v-for  = "item in visibleItems"
              :key   = "item.key"
              class  = "page-item"
              :class = "{ disabled: item.disabled, active: item.active }"
            >
                <component
                  :is    = "item.component"
                  class  = "page-link"
                  v-bind = "item.attrs"
                  v-on   = "item.listeners"
                >
                    <slot :name="item.slot" v-bind="item.slotData">{{ item.content }}</slot>
                </component>
            </li>
        </ul>
    </nav>
</template>
