import Vue       from "vue";
import VueSelect from "vue-select";

export const init_news_filter = (props) => {
  Vue.component('v-select', VueSelect)

  new Vue({
    el: '#news',
    data: {
      options:  props.filter_options,
      selected: props.selected,
      path:     props.path
    },
    updated: function () {
      window.location.href = this.path + '?' + this.selected.id
    }
  })
}
