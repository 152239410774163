/* app/views/devise/registrations/edit.html.slim */

import AbstractController       from "./abstract_controller"
import { confirm_payment_link } from './registrations/confirm_payment_link'
import { tabs_init }            from './registrations/tabs'
import WalletDatum              from './registrations/wallet_datum'

export default class extends AbstractController {
  static targets = [ "output" ]

  edit() {
    new WalletDatum()
    confirm_payment_link(this.element.dataset.registrationsLocaleValue)
    tabs_init()
  }
}