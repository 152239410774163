// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import http_vue from '../http/vue'
http_vue.init()

import 'jquery-autocomplete/jquery.autocomplete.js'
import 'jquery-autocomplete/jquery.autocomplete.css'

//region Sentry
import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://4a02f8cb4aee4499a15d29fcc692428f@o497728.ingest.sentry.io/5919877",

    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}
//endregion

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

window.$ = window.jQuery = $

import "controllers"

import "app"
