/**
 this will take in the headers from an API response and normalize them
 this way we don't run into production issues when nginx gives us lowercased header keys
 */
export default (headers) => {
  const upperCaseHeaders = {}

  Object.keys(headers.map || {}).forEach((e) => {
    upperCaseHeaders[e.toUpperCase()] = headers.map[e]
  })

  return upperCaseHeaders
}