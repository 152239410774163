import buildUrlWithCurrentLocation from "./build_url_with_current_location"
import {objectToQueryStringOmit}   from "./object_to_query_string"

/**
 * Based on the current location and the string parameters provided
 * creates a new entry in the history without reloading the page.
 *
 * @param {String} param
 */
export const historyPushState = (newUrl) => {
  window.history.pushState({ url: newUrl }, document.title, newUrl)
}

export const historyPushStateCurrentUrl = (params) => {
  historyPushState(
      buildUrlWithCurrentLocation(
          `?${objectToQueryStringOmit(params)}`
      )
  )
}