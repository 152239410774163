<script>
  import { mapState }  from 'vuex'
  import TableLoading  from "../../../../../../src/components/TableLoading/table_loading"

  export default {
    name: 'SummaryTable',
    components: { TableLoading },
    props: {
      transaction_types: { type: Array, required: true }
    },
    computed: {
      ...mapState([ 'summary', 'summaryTotals', 'summaryFetchInProgress' ])
    },
  }
</script>
<template>
    <div>
        <label>Summary</label>
        <table class="table table-striped table-hover">
            <thead>
                <th class="text-align-right">Type</th>
                <th class="text-center">Count</th>
                <th class="text-center">Total Amount</th>
            </thead>
            <tbody>
                <tr v-for="s in summary" :key="s.key" :class="s.css_class_tr">
                    <td class="text-align-right">{{ s.type }}</td>
                    <td>{{ s.count }}</td>
                    <td :class="s.css_class_amount"><strong>{{ s.amount_text }}</strong></td>
                </tr>
            </tbody>
        </table>
        <table-loading :active="summaryFetchInProgress"></table-loading>
    </div>
</template>