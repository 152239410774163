<script>
  import Vue            from "vue"
  import VueSelect      from "vue-select"
  import getParamByName from "../../../../../../../../src/utils/get_param_by_name"
  import {i18nUtil}     from "../../../../../../../../src/utils/i18n_utils"

  Vue.component('v-select', VueSelect)

  const NAME = 'FilterTransactionType'

  export default {
    name: NAME,
    props: {
      value: {
        type:       Number,
        required:   false,
        default:    null,
      },
      options: { type: Array, required: true }
    },
    mounted() {
      this.parseQuery()
      this.$root.$on('popstate', this.parseQuery) /*[h]*/
      // also filter will be reset when user clicks on any lead_id value in transactions table
      this.$root.$on(`${NAME}/reset`, () => this.selectedOption = null )
    },
    computed: {
      innerValue/*Number*/: {
        get()  { return this.value },
        set(v) { this.$emit('input', v) }
      },
      selectedOption/*Object{id,label}*/: {
        get()  { return this.find(this.innerValue) },
        set(v) { this.innerValue = (v ? v.id : v) /*when clear then v = undefined*/}
      },
    },
    methods: {
      parseQuery() {
        const incoming_type_id = getParamByName('type_id')
        this.$store.dispatch('filters/initQueryParsedFilter', { name: 'type_id', value: incoming_type_id })
        this.selectedOption = this.find(incoming_type_id)
      },
      find(type_id) {
        return this.options.find(x => x.id == type_id)
      },
      placeholder() {
        return i18nUtil.t('transactions.choose_type')
      }
    }
  }
</script>

<template>
    <v-select
      :options     = 'options'
      v-model      = 'selectedOption'
      :placeholder = "placeholder()"
      :clearable   = 'true'
    ></v-select>
</template>