const PRESELECTED = 'StatsDynamicColumns_preselected_'

/**
 *
 * when user changes select options - we puts selected values to cookies
 *
 * */
class StoreSelected {

  // store preselected into local storage
  static save(instance_id, selected) {
    const key = StoreSelected.key(instance_id)
    const val = JSON.stringify(selected)
    LocalStor.save(key, val)
  }

  // load from storage
  static load(instance_id) {
    const key = StoreSelected.key(instance_id)
    const val = LocalStor.fetch(key)
    if (val === undefined) return val

    const res = JSON.parse(val)
    //console.log(res)
    return res
  }

  // every instance has got own values
  static key(instance_id) {
    return [PRESELECTED, instance_id].join('')
  }
}

window.StoreSelected = StoreSelected
