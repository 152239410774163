<script>

  import { mapActions, mapState } from 'vuex'
  import Vue                      from "vue"
  import {Radio}                  from "../../../../../../../../../../src/components/inputs/Radio"
  import ButtonProgressOverlay    from "../../../../../../../../../../src/components/button_progress_overlay"

  Vue.component('ui-radio', Radio)

  const ACTION = 'updateLanding'

  export default {
    name: 'LandingsForm',
    props: {
      landings:   { type: Array,  required: true  },
      program:    { type: Object, required: true  },
      device:     { type: String,  required: true },
      country_id: { type: Number,  required: true },
    },
    data: function() {
      return {
        current: this.program.landing_id,
        old:     this.program.landing_id,
        loading: false
      }
    },
    mounted: function() {
      this.$btn = $('#'+this.btnId)
    },
    watch: {
      loading(newVal, oldVal) {
        if (newVal === oldVal) return

        if (newVal) {
          ButtonProgressOverlay.show(this.$btn)
        } else {
          ButtonProgressOverlay.hide(this.$btn)
        }
      }
    },
    computed: {
      ...mapState([
        'errors'
      ]),
      rbName() {
        return `program_${this.country_id}_${this.device}_${this.program.program_id}`
      },
      btnId() {
        let res = ['btn']
        res.push(this.country_id)
        res.push(this.device)
        res.push(this.program.program_id)
        return res.join('_')
      },
      isDirty() {
        return +this.current !== +this.old
      },
      thisErrors() {
        let errors = dig(this.errors, [ACTION, this.country_id, this.device, this.program.program_id])
        if (errors) {
          errors =
              Object.keys(errors).map(
                  field => errors[field][0]
              )
          if (errors) {
            errors = errors[0]
          }
        }
        return errors
      },
    },
    methods: {
      ...mapActions([ACTION]),
      onClickSave() {
        this.loading = true

        this[ACTION]({
          country_id: this.country_id,
          device:     this.device,
          program_id: this.program.program_id,
          landing_id: this.current
        }).then(
            () => {
              this.loading = false
              if (!this.thisErrors) {
                this.old = this.current
              }
            }
        )
      },
      onRadioChange(v) {
        this.current = v
      },
      rbChecked(landing) {
        return +this.old === +landing.id
      }
    }
  }
</script>

<template>
  <fragment>
    <table class="table">
      <tr v-for="landing in landings" :key="`landing_${landing.id}`">
        <td colspan="3">
          <ui-radio
              :id       = "landing.id"
              :label    = "landing.title"
              :value    = "landing.id"
              :checked  = "rbChecked(landing)"
              :name     = "rbName"
              :onChange = "onRadioChange"
          />
        </td>
      </tr>
      <tr :class='{ hidden: !isDirty }'>
        <td>
          <div class="position-relative d-inline-block primary">
            <button :id='btnId' class="btn btn-success" type="submit" @click="onClickSave">Save</button>
          </div>
          <div v-if="thisErrors" class="invalid-feedback" style="display: inline-block">{{ thisErrors }}</div>
        </td>
      </tr>
    </table>
  </fragment>
</template>