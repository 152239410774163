import Vue                            from 'vue'
import VueSelect                      from 'vue-select'
import {vselect_init, vselect_update} from "../../src/components/v_select"

//
// Форма регистрации аффилиата:
//   * заменит стандартные <select> в форме на VueSelect
//

export const init_aff_form = () => {
  Vue.component('v-select', VueSelect)

  new Vue({
    el: '#new_user',
    data: {
      country: '',
      locale:  '',
      options: { // of {label,id}
        countries: [],
        locales:   [],
      }
    },
    created: function() {

    },
    mounted: function() {
      this.vselect_mounted()
    },
    updated: function() {
      this.vselect_updated()
    },
    methods: {
      // методы связывающие стандартные невидимые <select> c элементами VueSelect, которыми они внешне заменены
      vselect_mounted: function() {
        vselect_init('.v-select__country', this.options.countries, 'country', this)
        vselect_init('.v-select__locale',  this.options.locales,   'locale', this)
      },
      vselect_updated: function() {
        vselect_update('.v-select__country', this.country.id)
        vselect_update('.v-select__locale',  this.locale.id)
      }
    }
  })
}
