/* app/views/user_subaccounts/_landings.html.slim */

/* Форма, с помощью которой предоставляется возможность указать ротацию лендингов при редактировании субаккаунта. */

import Vue                            from "vue"
import VueResource                    from "vue-resource"
import ButtonProgressOverlay          from "../../../src/components/button_progress_overlay"
import {vue_render_validation_errors} from "../../../src/components/vue_remote_forms"
import {vue_clear_validation_errors}  from "../../../src/components/vue_remote_forms"

export const init_save_landing_rotation_form = ({ save_landing_rotation_url, update_url_api_subaccounts_url, landing_id}) => {
  Vue.use(VueResource)

  if ($('#el_save_landing_rotation_form').length === 0) {
    return
  }

  new Vue({
    el: '#el_save_landing_rotation_form',
    data: {
      submitting:            false,
      submittingSaveLanding: false,
      resource:              Vue.resource(save_landing_rotation_url),
      resourceSaveLanding:   Vue.resource(update_url_api_subaccounts_url),
      current: {
        landingId: landing_id
      },
      dirty: {
        landingId: landing_id
      }
    },
    mounted: function() {
      this.current.clicks = this.landingsClicks()
      this.dirty.clicks   = this.landingsClicks()

      this.initClickSetLandingId()
      this.initClearClick()
    },
    updated: function() {
    },
    computed: {
      isDisabled() {
        return this.submitting
      },
      isDisabledSaveLanding() {
        return this.submittingSaveLanding || this.current.landingId == this.dirty.landingId
      },
      classButtonSaveLanding() {
        return {
          disabled: this.isDisabledSaveLanding
        }
      }
    },
    methods: {
      // сохранить ротацию лендингов
      onSubmitSaveLandingRotationForm(e) {
        e.preventDefault()
        if (this.isDisabled || !this.isDirtyClicks()) return

        const $btn = $(e.target)
        const that = this

        this.displayProgress($btn)
        vue_clear_validation_errors({ form: '#el_save_landing_rotation_form' })

        setTimeout(function() { that.sendRequest($btn) }, 500)
      },

      // сохранить выбранный лендинг в +user_subaccount.landing_id+
      onSubmitSaveLanding(e) {
        e.preventDefault()

        const $btn = $(e.target)
        const that = this

        this.displayProgress($btn)

        setTimeout(function() { that.sendRequestSaveLanding($btn) }, 500)
      },

      // Слушает клик по лендингам в таблице и подставляет их названия в текстовое поле +user_subaccount.landing_id+.
      initClickSetLandingId() {
        const that = this

        $(document).on('click', '.set-as-default', function (e) {
          e.preventDefault()
          const $td  = $(this).closest('td')
          const href = $td.find(':eq(0)').text()
          $('#landing').val(href)
          that.dirty.landingId = $td.prop('id')
        })
      },

      // Клик по иконке "крестик" удаляет название лендинга из текстового поля.
      initClearClick() {
        const that = this
        $('#clear_landing').click(function (e) {
          e.preventDefault()
          that.dirty.landingId = ''
          $('#landing').val('');
        })
      },

      onBlur(e) {
        let $item = $(e.currentTarget)
        this.$set(this.dirty.clicks, $item.data('landingId'), {clicks:$item.val()})
        this.$forceUpdate()
      },

      displayProgress($clickedBtn) {
        this.submitting = true
        ButtonProgressOverlay.show($clickedBtn)
      },
      hideProgress($clickedBtn) {
        this.submitting = false
        ButtonProgressOverlay.hide($clickedBtn)
      },
      sendRequest($btn) {
        this.resource
            .save({landings: this.landingsClicks()})
            .then(
                (_data) => {
                  this.hideProgress($btn)
                  this.current.clicks = this.landingsClicks()
                  this.dirty.clicks   = this.landingsClicks()
                },
                ({ body: { errors }}) => {
                  this.hideProgress($btn)
                  vue_clear_validation_errors({ form: '#el_save_landing_rotation_form' })
                  vue_render_validation_errors(errors)
                }
            )
      },
      sendRequestSaveLanding($btn) {
        this.resourceSaveLanding
            .save(this.saveLandingData())
            .then(
              () => {
                this.hideProgress($btn)
                const t = this.dirty.landingId
                this.current.landingId = t
              },
              ({ body: { errors }}) => {
                this.hideProgress($btn)
                vue_render_validation_errors(errors)
              }
            )
      },
      landingsClicks() {
        let res = {}
        $('#landings_table input.landing_clicks')
            .each(
                function() {
                  var $item = $(this)
                  res[$item.data('landingId')] = {clicks: $item.val()}
                }
            )
        return res
      },
      saveLandingData() {
        return {
          url: {
            landing_id: this.dirty.landingId,
            program_id: $('#program_id').val()
          }
        }
      },
      isDirtyClicks() {
        let res = false

        for (let lid in this.current.clicks) {
          if (this.current.clicks[lid].clicks !== this.dirty.clicks[lid].clicks) {
            res = true
            break
          }
        }

        return res
      }
    }
  })
}