import Vue                         from 'vue'
import {tabs_init}                 from "../registrations/tabs"
import {init_try_postback_feature} from "./edit_subaccount/try_postback_url"
import {init_postbacks_form}       from "./edit_subaccount/postbacks_form"

export const init_edit_smartlink = (view_object) => {
  new Vue({
    el:   '#edit_smartlink',
    data: {},
    created: function () {

    },
    mounted: function () {
      this.init_form()
      tabs_init()
      init_try_postback_feature(view_object)
      init_postbacks_form(view_object)
    },
    updated: function () {

    },
    methods: {
      init_form: function () {
        $('form.edit_user_subaccount').init_remote()
      }
    }
  })

}
