import Vue         from 'vue'
import {getCookie} from "../src/utils/get_cookie"
import toastr      from "toastr"

/** Совершит вызов +resourceCall+. Если в ответ придёт 401 Unauthorized, обновит
 *  токен и повторит вызов +functionToRepeat+.
 *
 * @param [Function] resourceCall       Например, Vue.resource('/change_payment').save
 * @param [Function] functionToRepeat   Функция (внутри которой находится вызов `dare`), которую необходимо повтороить с обновлённым токеном
 * @param [Function] onOk
 * @param [Function] onErr
 */
export const dare = function(resourceCall, functionToRepeat, onOk, onErr) {
  resourceCall().then(
      () => {
        onOk()
      },
      ({status}) => {
        if (refresh_csrf(status, functionToRepeat)) return
        onErr()
      }
  )
}

let tries = {}

// @return true Если был совершён повторный вызов функции +functionToRepeat+ с новым csrf token
const refresh_csrf = function(status, functionToRepeat) {
  // повторяем вызов с новым csrf только если ответ 401 Unauthorized
  if (status !== 401) return false

  // делаем 1 попытку
  const fnm = functionToRepeat.name
  if (tries[fnm] === undefined) tries[fnm] = 0
  const count = tries[fnm]
  if (count >= 1) {
    toastr['error']('Unauthorized')
    return false
  }
  tries[fnm] = count + 1

  // сервер должен был прислать новый токен
  let token = getCookie('XSRF-TOKEN')
  if (!token) {
    toastr['error']('Unauthorized')
    return false
  }

  // вызов c обновлённым токеном
  Vue.http.headers.common['X-CSRF-Token'] = unescape(token)
  functionToRepeat()
  return true
}
