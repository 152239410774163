import * as types from './mutation_types'

export const initQueryParsedFilter = ({ commit }, { name, value }) => {
  commit(types.INIT_QUERY_PARSED_FILTER, { name, value })
}

export const updateFilters = ({ commit }, newFilters) => {
  commit(types.UPDATE_FILTERS, newFilters)
}

export const updateFilter = ({ commit }, { name, value }) => {
  commit(types.UPDATE_FILTER, { name, value })
}