import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {

    this.$hiddenBlock          = $('.hidden-block', this.element);
    this.hiddenBlockCheckboxes = $('.hidden-block input[type="checkbox"]', this.element);

    const that = this;

    function toggle(e) {
      if (that.$hiddenBlock.hasClass('hidden')) {
        that.$hiddenBlock.removeClass('hidden')
      } else {
        that.$hiddenBlock.addClass('hidden')
      }
      return true
    }

    function setChildren() {
      that.hiddenBlockCheckboxes.prop('checked', this.checked)
    }

    $(this.element).on('click.checkbox_tree',  '#datingBlockSwitcher', toggle);
    $(this.element).on('click.checkbox_tree',  '#datingBlockSwitcher input[type="checkbox"]', toggle);
    $(this.element).on('change.checkbox_tree', '#datingBlockSwitcher input[type="checkbox"]', setChildren);
  }

  disconnect() {
    $(this.element).off('click.checkbox_tree');
    $(this.element).off('change.checkbox_tree');
  }
}
