import './store_selected'

/**
 * Shows/hides dynamic table columns on the pages «Reports / Conversion Report» and «Reports / Click Report»
 *
 * lib/helpers/stats_dynamic_columns.rb
 *
 */
class StatsDynamicColumns {

  // Instantiates new StatsDynamicColumns instances
  static init() {
    let $el = $('.filter_dync')
    if ($el.length > 0) {
      for (let i = 0; i < $el.length; i++) {
        new StatsDynamicColumns($el[i])
      }
    }
  }

  constructor(node_wrapper) {
    this.id        = node_wrapper.id
    this.$el       = $(node_wrapper).find('.form-control.select2').select2()
    this.dict      = this.$el.data('dict')
    this.on_change = this.on_change.bind(this)

    this.init()
  }

  init() {
    this.set_preselected()
    this.$el.on('change', this.on_change)
    this.on_change()
  }

  // set preselected values when component started
  set_preselected() {
    let pre_selected = this.$el.data('preSelected')
    if (pre_selected === undefined || pre_selected.length === 0) {
      pre_selected = StoreSelected.load(this.id)
    }
    if (pre_selected === undefined || pre_selected.length === 0) return

    this.$el.val(pre_selected)
    this.$el.trigger('change')
  }

  // when user changes select options
  //   - we puts selected values to cookies
  //   - show/hide dynamic columns
  on_change() {
    let selected = this.$el.select2('data').map(el => el.id)
    // console.log(selected)
    StoreSelected.save(this.id, selected)
    this.redraw_columns(selected)
  }

  redraw_columns(selected) {
    if (this.dict === undefined || this.dict.length === 0) {
      $('.dync').addClass('hidden')
      return
    }

    let i, col_id, $col
    for (i = 0; i < this.dict.length; i++) {
      col_id = this.dict[i]
      $col   = $('.dyn_' + col_id)
      if (selected.indexOf(col_id) >= 0) {
        $col.removeClass('hidden')
      } else {
        $col.addClass('hidden')
      }
    }
  }

}

document.addEventListener("DOMContentLoaded", () => StatsDynamicColumns.init())
