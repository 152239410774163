// app/views/user_subaccounts/edit_tds/tab_content/_common.html.slim

import toastr from "toastr"

import {
  handle_changes,
  handle_del_traffic_back_response,
  handle_traffic_back_response,
  init_add_traffic_back
} from '../../../src/components/add_trafficback_component'

export const init_common_form = ({ tpl_traffic_back }) => {
  $('div#common form').init_remote({
    on_success: (data) => {
      handle_traffic_back_response(data.user_subaccount)
      toastr[data.growl.type](data.growl.text)
      handle_del_traffic_back_response(data.user_subaccount)
      handle_changes(data.user_subaccount)
    }
  })
  init_add_traffic_back(tpl_traffic_back)
}