import Vue        from "vue"
import Vuex       from "vuex"
import actions    from "./actions"
import mutations  from "./mutations"
import state      from "./state"
import pageInfo   from '../../../../stores/modules/page_info'
import filters    from '../../../../stores/modules/filters'

Vue.use(Vuex)

// Урл, по которому можно извлечь страны (в него уже вписан user_subaccount.id) передаётся из контроллера в view_object.

export const createStore = (view_object) => ({
  actions,
  mutations,
  state: state(view_object),
  modules: {
    pageInfo: pageInfo,
    filters:  filters
  }
})

export default (view_object) => {
  return new Vuex.Store(createStore(view_object))
}