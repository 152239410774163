import $ from "jquery";
import moment from 'moment/moment';

function dateRangePickerDefaultOptions(subtractDays, single) {
  return {
    startDate: moment().subtract(subtractDays, 'days'),
    linkedCalendars: false,
    endDate: moment(),
    minDate: '01.01.2016',
    maxDate: moment(), // maxDate: '12.31.2018',
    maxSpan: {days: 600},
    showDropdowns: true,
    showWeekNumbers: true,
    timePicker: false,
    timePickerIncrement: 1,
    timePicker12Hour: true,
    opens: 'right',
    buttonClasses: ['btn btn-default'],
    applyClass: 'btn-small btn-primary',
    cancelClass: 'btn-small',
    singleDatePicker: single,
  }
}

function i18n_t(locale, key) {
  let result = key;

  let tmp = window.i18n[locale];
  if (!tmp) tmp = window.i18n['en'];

  key.split('.').forEach(value => tmp = tmp && tmp[value]);

  return tmp || result;
}

function genOptionSet1(locale, subtractDays, single) {
  const localeOptions = {
    locale: {
      format: 'DD.MM.YYYY',
      applyLabel: i18n_t(locale, "datepicker.applyLabel"),
      cancelLabel: i18n_t(locale, "datepicker.cancelLabel"),
      customRangeLabel: i18n_t(locale, "datepicker.customRangeLabel"),
      firstDay: 1,
      daysOfWeek: [i18n_t(locale, "datepicker.daysOfWeek.sunday"), i18n_t(locale, "datepicker.daysOfWeek.monday"), i18n_t(locale, "datepicker.daysOfWeek.tuestay"), i18n_t(locale, "datepicker.daysOfWeek.wednesday"), i18n_t(locale, "datepicker.daysOfWeek.thursday"), i18n_t(locale, "datepicker.daysOfWeek.friday"), i18n_t(locale, "datepicker.daysOfWeek.saturday")],
      monthNames: [i18n_t(locale, "datepicker.monthNames.january"), i18n_t(locale, "datepicker.monthNames.february"), i18n_t(locale, "datepicker.monthNames.march"), i18n_t(locale, "datepicker.monthNames.april"), i18n_t(locale, "datepicker.monthNames.may"), i18n_t(locale, "datepicker.monthNames.june"), i18n_t(locale, "datepicker.monthNames.july"), i18n_t(locale, "datepicker.monthNames.august"), i18n_t(locale, "datepicker.monthNames.september"), i18n_t(locale, "datepicker.monthNames.october"), i18n_t(locale, "datepicker.monthNames.november"), i18n_t(locale, "datepicker.monthNames.december")],
    },
  };
  if (!single) {
    localeOptions.ranges = {};
    localeOptions.ranges[i18n_t(locale, 'datepicker.ranges.today')] = [moment(), moment()];
    localeOptions.ranges[i18n_t(locale, 'datepicker.ranges.yesterday')] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
    localeOptions.ranges[i18n_t(locale, 'datepicker.ranges.last_seven_days')] = [moment().subtract(6, 'days'), moment()];
    localeOptions.ranges[i18n_t(locale, 'datepicker.ranges.last_thirty_days')] = [moment().subtract(29, 'days'), moment()];
    localeOptions.ranges[i18n_t(locale, 'datepicker.ranges.this_month')] = [moment().startOf('month'), moment().endOf('month')];
    localeOptions.ranges[i18n_t(locale, 'datepicker.ranges.last_month')] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
  }

  return {...dateRangePickerDefaultOptions(subtractDays, single), ...localeOptions}
}

function genOptionSet2(locale, subtractDays) {
  return {
    startDate: moment().subtract(subtractDays, 'days'),
    endDate: moment(),
    opens: 'left',
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
  }
}

function reportRangeDatePicker(reportRangeName, subtractDays1, subtractDays2) {
  const rangeEl = $(`#${reportRangeName}`);
  if (rangeEl.length === 0) return;

  const locale = rangeEl.data('locale');
  const single = rangeEl.data('single');

  const cb = function(start, end) {
    if (single) {
      $(`#${reportRangeName} .form-control`).val(start.format('DD.MM.YYYY'));
    } else {
      $(`#${reportRangeName} .form-control`).val(start.format('DD.MM.YYYY') + ' - ' + end.format('DD.MM.YYYY'));
    }
    // alert("Callback has fired: [" + start.format('MMMM D, YYYY') + " to " + end.format('MMMM D, YYYY') + "]");
  };

  const rangeElFormControl = $(`#${reportRangeName} .form-control`);
  if(!rangeElFormControl.val()) {
    if (single) {
      rangeElFormControl.val(moment().subtract(subtractDays1, 'days').format('DD.MM.YYYY'));
    } else {
      rangeElFormControl.val(moment().subtract(subtractDays1, 'days').format('DD.MM.YYYY') + ' - ' + moment().format('DD.MM.YYYY'));
    }
  }

  const optionSet1 = genOptionSet1(locale, subtractDays1, single);
  const optionSet2 = genOptionSet2(locale, subtractDays2, single);
  rangeEl.daterangepicker(optionSet1, cb);

  if (single) {
    $('.drp-selected').hide();
  }

  // rangeEl.on('show', function() { console.log("show event fired"); });
  // rangeEl.on('hide', function() { console.log("hide event fired"); });
  // rangeEl.on('apply', function(ev, picker) {
  //   console.log("apply event fired, start/end dates are "
  //       + picker.startDate.format('MMMM D, YYYY')
  //       + " to "
  //       + picker.endDate.format('MMMM D, YYYY')
  //   );
  // });
  // rangeEl.on('cancel', function(ev, picker) { console.log("cancel event fired"); });

  $('#options1').click(function() {
    rangeEl.data('daterangepicker').setOptions(optionSet1, cb);
  });

  $('#options2').click(function() {
    rangeEl.data('daterangepicker').setOptions(optionSet2, cb);
  });

  $('#destroy').click(function() {
    rangeEl.data('daterangepicker').remove();
  });
}

document.addEventListener("DOMContentLoaded", () => {
  reportRangeDatePicker('reportRange', 3333, 6);
  reportRangeDatePicker('reportRange2', 29, 7);
});
