import {i18nUtil} from "../../../src/utils/i18n_utils"

export const decorateTransactions = (transactions) => {
  let t, i, l = transactions.length
  for (i = 0; i < l; i++) {
    t = transactions[i]

    t.css_class_amount = t.from_master ? 'text-success' : 'text-danger'
    t.amount_text      = t.from_master ? t.amount : `- ${t.amount}`

    transactions[i] = t
  }
  return transactions
}

export const decorateSummary = (summary) => {
  let s, i, l = summary.length
  for (i = 0; i < l; i++) {
    s = summary[i]

    s.css_class_amount = MINUS.indexOf(s.type_id)>=0 ? 'text-danger'   : 'text-success'
    s.amount_text      = MINUS.indexOf(s.type_id)>=0 ? `- ${s.amount}` : s.amount

    s.css_class_tr     = TOTAL.indexOf(s.type_id)>=0 ? 'summary' : ''
    s.css_class_amount = TOTAL.indexOf(s.type_id)>=0 ? ''        : s.css_class_amount
    s.type             = TOTAL.indexOf(s.type_id)<0  ? s.type    : i18nUtil.t('stats.columns.all_s')

    summary[i] = s
  }
  return summary
}

const MINUS = 'deduction,prepayment'
const TOTAL = 'totals'