
import $ from "jquery"
import "timeago/jquery.timeago"

// en ru ar bn es fr
import en from "./jquery.timeago.en"
import ar from "./jquery.timeago.ar"
import es from "./jquery.timeago.es"
import fr from "./jquery.timeago.fr"
import ru from "./jquery.timeago.ru"

function initTimeAgo() {
    const lang = $('html').attr('lang')
    const locales = {en, ar, es, fr, ru}
    const locale = locales[lang]

    if (locale) {
        jQuery.timeago.settings.strings = locale
    }

    $('time').timeago();
};

document.addEventListener("DOMContentLoaded", initTimeAgo);
