// app/views/user_subaccounts/_url_generator.html.slim

$(function() {
  const mainInput = $('#generatedUrl');

  if (mainInput.length === 0) {
    return
  }

  const baseUrl = mainInput.data('url');
  let trackerUrl = baseUrl;

  $('.makeUrl').click(function (e) {
    e.preventDefault();
    trackerUrl = baseUrl;
    const tracker = $(this).data('tracker');

    if (tracker == 'keitaro') {
      trackerUrl += '?clickid={subid}';
    } else if (tracker == 'cpatracker') {
      trackerUrl += '?clickid=[SUBID]';
    }
    mainInput.val(trackerUrl);
    $('#toRedirect').attr('href', trackerUrl);
  });

  $('#generateUrl').click(function (e) {
    e.preventDefault();

    trackerUrl = baseUrl + '?';
    let params = {
      clickid: $('#clickid').val(),
      source: $('#source').val(),
      sub1: $('#sub1').val(),
      sub2: $('#sub2').val(),
      sub3: $('#sub3').val(),
      sub4: $('#sub4').val(),
      sub5: $('#sub5').val(),
      utm_medium: $('#utm_medium').val(),
      utm_source: $('#utm_source').val(),
      utm_campaign: $('#utm_campaign').val(),
      utm_term: $('#utm_term').val(),
      utm_content: $('#utm_content').val()
    };

    $.each(params, function (index, value) {
      if (value == '') delete params[index];
    });

    trackerUrl += decodeURIComponent($.param(params));

    $('#toRedirect').attr('href', trackerUrl);

    mainInput.val(trackerUrl);
  });
});