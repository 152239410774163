import Vue     from 'vue'
import VueI18n from 'vue-i18n'
import $       from "jquery"

import '../i18n'

function getI18nLocale() {
  return $('html').attr('lang')
}

function getVueI18n() {
  var vuei18nTmp = window.vuei18n
  if (vuei18nTmp) return vuei18nTmp

  Vue.use(VueI18n)

  vuei18nTmp = new VueI18n({
    locale:   getI18nLocale(),
    messages: window.i18n
  });

  if (vuei18nTmp) window.vuei18n = vuei18nTmp
  return vuei18nTmp
}

export const i18nUtil = {
  t(key) {
    if (!key) return ''
    const i18n = getVueI18n()
    i18n.locale = getI18nLocale()
    return i18n.t(key)
  },

  getI18nLocale() {
    return getI18nLocale()
  },
}