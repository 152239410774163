<script>

  import { mapState, mapActions } from 'vuex'
  import ButtonProgressOverlay    from "../../../../../../../../../../src/components/button_progress_overlay"

  import './style.scss'

  const ACTION = 'updateClicks'

  export default {
    name: 'ClicksForm',
    props: {
      country_id:             { type: Number,  required: true },
      program_id:             { type: Number,  required: true },
      program_clicks:         { type: Number,  required: true },
      device:                 { type: String,  required: true },
      is_tds_setting_active:  { type: Boolean, required: true },
    },
    data: function() {
      return {
        current: null,
        old:     null,
        loading: false
      }
    },
    mounted: function() {
      this.current = this.program_clicks
      this.old     = this.program_clicks
      this.$btn    = $('#'+this.btnId)

      // юзер нажал на кнопку "распределить равномерно"
      this.$root.$on('evenly', this.onInputExternal)

      // for RSPEC
      window[this.btnId] = this
    },
    watch: {
      loading(newVal, oldVal) {
        if (newVal === oldVal) return

        if (newVal) {
          ButtonProgressOverlay.show(this.$btn)
        } else {
          ButtonProgressOverlay.hide(this.$btn)
        }
      }
    },
    computed: {
      ...mapState([
        'errors'
      ]),
      isDirty() {
        return +this.current !== +this.old
      },
      thisErrors() {
        let errors = dig(this.errors, [ACTION, this.country_id, this.device, this.program_id])
        if (errors) {
          errors =
              Object.keys(errors).map(
                  field => errors[field][0]
              )
          if (errors) {
            errors = errors[0]
          }
        }
        return errors
      },
      btnId() {
        let res = []
        res.push(this.country_id)
        res.push(this.device)
        res.push(this.program_id)
        return res.join('_')
      }
    },
    methods: {
      ...mapActions([ACTION]),
      onInputExternal({ rkey, percent }) {
        const [country_id, device] = rkey.split('_')
        if (!(+this.country_id === +country_id && this.device === device)) return

        this.current = percent
        this.$forceUpdate()
      },
      onInput(e) {
        let $item = $(e.currentTarget)
        this.current = $item.val()
        this.$forceUpdate()
      },
      onClickSave() {
        this.loading = true

        this[ACTION]({
          country_id: this.country_id,
          device:     this.device,
          program_id: this.program_id,
          clicks:     this.current
        }).then(
            () => {
              this.loading = false
              if (!this.thisErrors) {
                this.old = this.current
              }
            },
            () => { this.loading = false },
        )
      }
    }
  }
</script>

<template>
  <td class="clicks_form">
    <template v-if="is_tds_setting_active">
                            <input class="form-control" type="text" disabled :value="program_clicks">
    </template>
    <template v-else>
                            <div :class="{ 'input-group': true, 'has-error': thisErrors }">
                              <input class="form-control input-sm" type="text" :name="btnId" :value="current" @input='onInput'>
                              <div class="input-group-append">
                                <button :id='btnId' :class='{ opacity0: !isDirty }' class="btn btn-success input-group-text" type="submit" @click="onClickSave">Save</button>
                              </div>
                            </div>
                            <div v-if="thisErrors" class="invalid-feedback">{{ thisErrors }}</div>
    </template>
  </td>
</template>