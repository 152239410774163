export const REQUEST_COUNTRIES         = 'REQUEST_COUNTRIES'
export const REQUEST_COUNTRIES_SUCCESS = 'REQUEST_COUNTRIES_SUCCESS'
export const REQUEST_COUNTRIES_FAIL    = 'REQUEST_COUNTRIES_FAIL'

export const ACTIVATE_PROGRAM         = 'ACTIVATE_PROGRAM'
export const ACTIVATE_PROGRAM_SUCCESS = 'ACTIVATE_PROGRAM_SUCCESS'
export const ACTIVATE_PROGRAM_FAIL    = 'ACTIVATE_PROGRAM_FAIL'

export const UPDATE_CLICKS            = 'UPDATE_CLICKS'
export const UPDATE_CLICKS_SUCCESS    = 'UPDATE_CLICKS_SUCCESS'
export const UPDATE_CLICKS_FAIL       = 'UPDATE_CLICKS_FAIL'

export const UPDATE_LANDING            = 'UPDATE_LANDING'
export const UPDATE_LANDING_SUCCESS    = 'UPDATE_LANDING_SUCCESS'
export const UPDATE_LANDING_FAIL       = 'UPDATE_LANDING_FAIL'

export const MAKE_OFFER_JUST_ACTIVATED = 'MAKE_OFFER_JUST_ACTIVATED'