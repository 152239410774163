import moment          from "moment"
import {FORMAT_SERVER} from './formats'

export class DateRange {

  // @param  [String] d - server friendly date range .     Example: "2001.01.21 — 2001.02.21"
  // @return [String] user friendly localized date range . Example: "21/01/2001 — 21/02/2001"
  static localize(d, f) {
    if (d === null || d === undefined) return
    var [a, b] = d.split(this.RANGE_SEPARATOR)
    var aa = moment(a, FORMAT_SERVER).format(f)
    var bb = moment(b, FORMAT_SERVER).format(f)
    return this.range(aa, bb)
  }

  // @param [String] date_range - user friendly localized date range
  // @return [String] server friendly date range
  static normalize(d, f) {
    if (d === null || d === undefined) return
    var [a, b] = d.split(this.RANGE_SEPARATOR)
    var aa = moment(a, f).format(FORMAT_SERVER)
    var bb = moment(b, f).format(FORMAT_SERVER)
    return this.range(aa, bb)
  }

  static range = (d1, d2, f) => {
    if (d1 === 'Invalid date' || d2 === 'Invalid date') return
    if (f) {
      d1 = d1.format(f)
      d2 = d2.format(f)
    }
    return [d1, d2].join(this.RANGE_SEPARATOR)
  }
}

DateRange.RANGE_SEPARATOR = ' — '