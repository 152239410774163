// определяет TimeZone на основе формата времени и кладёт её в cookies для ApplicationController#set_time_zone
try {
    const format = new Intl.DateTimeFormat()
    const timeZone = format.resolvedOptions().timeZone

    document.cookie = `tz=${timeZone}; path=/; expires=Sat, 12 Jan 2047 15:03:15 GMT`
} catch (e) {
    console.log("Error time zone detection", e)
}

