/*
  Сгенерировать и показать сообщение +message+, завёрнутое в Bootstrap modal, с кнопкой с надписью +close+ и заголовком +header+.
  Когда юзер закрывает модальное окно - происходит вызов функции +on_close+.
 */

export default function show_simple_modal(message, header, close, on_close) {
  let popupTemplate =
    '<div class="modal fade">' +
    '  <div class="modal-dialog">' +
    '    <div class="modal-content">' +
    '      <div class="modal-header">' +
    '        <button type="button" class="close" data-dismiss="modal">&times;</button>' +
    `        <h4 class="modal-title">${header}</h4>` +
    '      </div>' +
    `      <div class="modal-body">${message}</div>` +
    '      <div class="modal-footer">' +
    `        <button type="button" class="btn btn-primary" data-dismiss="modal">${close}</button>` +
    '      </div>' +
    '    </div>' +
    '  </div>' +
    '</div>'

  $(popupTemplate).modal('show').on('hidden.bs.modal', on_close)
}
