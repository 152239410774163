<script>
  import ActivatedProgramRow     from "./components/ActivatedProgramRow/activated_program_row"
  import NotActivatedProgramRow  from "./components/NotActivatedProgramRow/not_activated_program_row"

  export default {
    name: 'ProgramRow',
    components: { ActivatedProgramRow, NotActivatedProgramRow },
    props: {
      country_id:       { type: Number,  required: true },
      program:          { type: Object,  required: true },
      landings:         { type: Array,   required: true },
      device:           { type: String,  required: true },
      activated:        { type: Boolean, required: true },
      new_activations:  { type: Object,  required: true },
    },
  }
</script>

<template>
  <activated-program-row     v-if="activated" :country_id="country_id" :program="program" :device="device" :landings="landings"></activated-program-row>
  <not-activated-program-row v-else           :country_id="country_id" :program="program" :device="device" :new_activations="new_activations"></not-activated-program-row>
</template>