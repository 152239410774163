import Vue         from 'vue'
import VueResource from 'vue-resource'
import toastr      from "toastr"

export default {
  init() {

    Vue.use(VueResource)
    Vue.http.interceptors.push(
        function(request, next) {
          next(({body}) => {
            if (body && body.growl) toastr[body.growl.type](body.growl.text)
          })
        }
    )

    Vue.http.interceptors.push(
      function(_request) {
        return function({ body, status }) {
          if (status < 200 || status >= 300) {
            if (body) {
              const { errors_in_toastr } = body
              if (errors_in_toastr && typeof errors_in_toastr === 'object') {
                Object.values(errors_in_toastr).forEach(({ field, errors }) => {
                  toastr.error(`${field} - ${errors}`)
                })
              }
            }
          }
        }
      }
    )

    Vue.http.headers.common['X-CSRF-Token'] = $('[name="csrf-token"]').attr('content')
  }
}