/* app/views/devise/registrations/edit.html.slim */

import AbstractController       from "./abstract_controller"
import { init_activation_form } from './programs/init_activation_form'

export default class extends AbstractController {
  static targets = [ "output" ]

  show() {
    init_activation_form()
  }
}