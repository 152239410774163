import Vue from 'vue'
import { mapGetters } from 'vuex'

Vue.component(
  'stats-header-group',
  {
    props: {
      keys: Array,
      title: String
    },
    computed: {
      ...mapGetters('columns', ['displaySettings']),
      activeSize() {
        return this.keys.reduce((res, key) => res += this.displaySettings[key] ? 1 : 0, 0)
      }
    },
    template: "<th v-if='activeSize > 0' :colspan='activeSize' class='text-center'><strong>{{ title }}</strong></th>"
  }
)
