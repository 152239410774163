/** Из опций заменяемого селекта +sel_target+ (из html-кода страницы)
 * составить опции для v-select в +data_options+,
 * в +data_model+ Vue приложения +that+ поместить начальное значение.
 *
 * = select_tag :'user[profile_attributes[address_detail_attributes]][country_id]', options, class: 'v-select v-select__country', required: 'required', 'aria-required': true, include_blank: true
 * v-select dir=(rtl? ? 'rtl' : 'ltr') :options='options.countries' v-model='country' :clearable='false'
 *
 * app/javascript/src/scss/components/v_select.scss
 *
 * @param {string} sel_target  - css-селектор заменяемого селекта
 * @param {array} data_options - ссылка на массив с VueSelect's options
 * @param {string} data_model  - ключ в объекте that: VueSelect's selected value
 * @param that                 - ссылка на Vue-приложение
 * @param opts                 - опции рендера. Например, отсортировать опции селекта по id.
 */
export const vselect_init = function(sel_target, data_options, data_model, that, opts = {}) {
  const $sel = $(sel_target),
        sel_val = $sel.val(),
        options = $(sel_target).find('option')

  // обходим опции заменяемого селекта
  for (let i = 0, $option, val, text; i < options.length; i++) {
    $option = $(options[i])
    val = $option.attr('value')
    text = $option.text()

    // собираем опции для VueSelect
    data_options.push({ label: text, id: val })

    // устанавливаем selected value
    if(val === sel_val && val !== '') {
      that[data_model] = { label: text, id: val }
    }
  }

  if (opts.sort_by_integer_id) {
    data_options.sort(compare_integer_id)
  }
}

/** В заменённый и визуально скрытый селект +sel_target+
 *  поместить значение +value+
 *
 * @param sel_target
 * @param value
 */
export const vselect_update = function(sel_target, value) {
  if (value !== undefined) $(sel_target).val(value)
}

function compare_integer_id( a, b ) {
  if ( +a.id < +b.id ) {
    return -1;
  }
  if ( +a.id > +b.id ) {
    return 1;
  }
  return 0;
}