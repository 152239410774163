// каждый filter-компонент
//  * его mounted срабатывает впервую очередь, потом срабатывает mounted компонента-родителя
//  * по-этому, filter-компонент самостоятельно парсит GET-параметры с целью предустановки начальных значений
export const INIT_QUERY_PARSED_FILTER = 'INIT_QUERY_PARSED_FILTER'

// обновить данные [после того, как юзер выставил фильтры и нажал кнопку "Filter"]
export const UPDATE_FILTERS = 'UPDATE_FILTERS'

// обновить один фильтр
export const UPDATE_FILTER = 'UPDATE_FILTER'
