import moment       from "moment"
import {i18nUtil}   from '../../../utils/i18n_utils'
import * as formats from './formats'
import {DateRange}  from "./date_range"

const locale = i18nUtil.getI18nLocale()
const format = () => formats.ALL[locale]
const today = moment().format(format())

export const settings = {
  defaultValue: () => DateRange.range(today, today),
  format: format(),
  locale: locale,
  ranges: {
    ["" + (i18nUtil.t('datepicker.ranges.today'))]:            [moment(),                                       moment()                                    ],
    ["" + (i18nUtil.t('datepicker.ranges.yesterday'))]:        [moment().subtract(1, 'days'),                   moment().subtract(1, 'days')                ],
    ["" + (i18nUtil.t('datepicker.ranges.last_seven_days'))]:  [moment().subtract(6, 'days'),                   moment()                                    ],
    ["" + (i18nUtil.t('datepicker.ranges.last_thirty_days'))]: [moment().subtract(29, 'days'),                  moment()                                    ],
    [(i18nUtil.t('datepicker.ranges.this_month')) + " "]:      [moment().startOf('month'),                      moment().endOf('month')                     ],
    ["" + (i18nUtil.t('datepicker.ranges.last_month'))]:       [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  }
}

