<script>
  import { mapState }          from 'vuex'
  import FilterDateRange       from "../../../../../../src/components/inputs/FilterDateRange/filter_date_range"
  import FilterTransactionType from "./components/FilterTransactionType/filter_transaction_type"
  import FilterOrderId         from "./components/FilterOrderId/filter_order_id"
  import TableLoading          from "../../../../../../src/components/TableLoading/table_loading"
  import {getChildrenNames}    from "../../../../../../src/utils/vue/get_child_names"

  export default {
    name: 'TableFilter',
    components: { FilterDateRange, FilterTransactionType, FilterOrderId, TableLoading },
    props: {
      submit:            { type: Function, required: true },
      transaction_types: { type: Array, required: true }
    },
    mounted() {
      this.$store.dispatch('registerFilters', getChildrenNames(this))
      this.$store.dispatch('fetchInitTransactions', this.innerFilters)
      this.$root.$on('popstate', () => this.submit(this.innerFilters, false)) /*[h]*/
    },
    computed: {
      ...mapState(['transactionsFetchInProgress'])
    },
    data() {
      return {
        innerFilters: {}
      }
    },
    methods: {
      onSubmit(e) {
        if (e) e.preventDefault()
        this.submit(this.innerFilters)
      },
      onClear(e) {
        if (e) e.preventDefault()
        this.$store.dispatch('resetFiltersAndPagination', this)
      },
      onDownload(e) {
        if (e) e.preventDefault()
        this.$store.dispatch('fetchExport')
      }
    }
  }
</script>

<template>
    <form>
        <div class="form-row by_date_range">
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="control-label">Date</label>
                    <filter-date-range v-model="innerFilters.date_range" ></filter-date-range>
                </div>
            </div>
        </div>

        <div class="form-row by_type_id">
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="control-label">Type</label>
                    <filter-transaction-type v-model="innerFilters.type_id" :options="transaction_types"></filter-transaction-type>
                </div>
            </div>
        </div>

        <div class="form-row by_lead_id">
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="control-label">Lead id</label>
                    <filter-order-id v-model="innerFilters.transaction_id"></filter-order-id>
                </div>
            </div>
        </div>

        <div class="form-row">
            <div class="col-12 mb-3">
                <input class="btn btn-primary btn-submit" type="submit" value="Filter" @click="onSubmit" />
                <input class="btn btn-clear" type="submit" value="Clear" @click="onClear" />
                <button class="btn btn-info float-right" type="submit" @click="onDownload">
                    Export
                    <i class="icon icon-file-excel-o" style="margin-left:7px;display:inline-block"></i>
                </button>
            </div>
            <table-loading :active="transactionsFetchInProgress"></table-loading>
        </div>
    </form>
</template>