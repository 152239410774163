import {omitPaginationParams} from "./omit_pagination_params"
import {omitNullAndUndefined} from "./omit_null_and_undefined"

/**
 * Converts object with key-value pairs
 * into query-param string
 *
 * @param {Object} params
 */
export const objectToQueryString = (params = {}) =>
    Object.keys(params)
        .map((param) => `${param}=${params[param]}`)
        .join('&')

/**
 * Omit some pagination params.
 *
 * @param params
 * @returns {*}
 */
export const objectToQueryStringOmit = (params = {}) =>
    objectToQueryString(
        omitNullAndUndefined(
           omitPaginationParams(params)
       )
    )
