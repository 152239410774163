import toastr                from "toastr"
import ButtonProgressOverlay from "./button_progress_overlay"
import {inView}              from "../utils/in_view"
import {getCookie}           from "../utils/get_cookie"

/** Обрабатывает события Ajax запросов рельсовых форм `remote: true`
    (рельсовые формы - это формы, построенные с помощью рельсовых (и не только) хелперов: form_for, simple_form_for,...)

:: Чтобы инициализировать форму, достаточно вызвать метод `init_remote`. Например:
      $('form#new_user').init_remote()

      или с дополнительным обработчиком
      $('form#new_user').init_remote({ on_success: (data) => { console.log(data) })

:: Для показа ошибок валидации::

 1. Если форма построена с привязкой к модели с помощью `simple_form_for(resource)`:
    - в форму обязательно добавить имя модели (по сути - префикс айдишников инпутов):
      например, `data: { model: 'user'}`
    - сервер должен присылать json вида :
            * {"errors":{"first_name":"bad name", "email":"bad email", "promo_site":"bad value"}}
            * или (вложенные модели): {
                "errors": {
                  "web_traffic_backs_attributes": {
                    "1622605823227": {
                      "country_id": [
                        "must be an integer"
                      ],
                      "url":        [
                        "must be a string"
                      ]
                    },
                    "1622605831745": {
                      "country_id": [
                        "must be an integer"
                      ],
                      "url":        [
                        "must be a string"
                      ]
                    },
                    "1622605832149": {
                      "country_id": [
                        "must be an integer"
                      ],
                      "url":        [
                        "must be a string"
                      ]
                    }
                  },
                  "wap_traffic_backs_attributes": {
                    "1622605834580": {
                      "country_id": [
                        "must be an integer"
                      ],
                      "url":        [
                        "is in invalid format"
                      ]
                    }
                  }
                },
                "growl":  null
              }

 2. Если форма построена без привязки к модели `form_tag ...`:
    - в форму не надо добавлять data-атрибут `data: {model '..'}
    - сервер должен присылать json вида как в п.1
    - пример: user_subaccounts/new_smartlink.html.slim

:: Для показа исключительных ошибок (например, бизнес-логики) используется toastr ::
    - для этого необходимо прислать json вида `{errors:{text:['message']}}` со статусом :forbidden
    - если не прислать json упомянутой структуры, будет показан toastr с дефолтным описанием http-статуса
    - если на сервере при обработке запроса случилось 500, будет показан toastr с дефолтным описанием http-статуса 500

:: Для автоскролла к полю с ошибкой валидации ::
    - необходимо добавить к форме `data: { scroll2error: true }`
*/

(function($) {

  // если у формы `data-scroll2error=true` - в случае получения ошибок валидации будет
  // произведён автоматический скролл к этим полям
  const scroll_to_error = function() {
    let $errors = $('.has-error')
    if ($errors.length === 0) return

    let $error = $errors.first(),
       $parent = $error.parent(),
       top     = $parent.offset().top

    let py  = window.pageYOffset || document.documentElement.scrollTop
    let pyh = py + window.innerHeight
    if (top < py || top > pyh) {
      if (!inView($error[0], 100)) {
        $('html').animate({ scrollTop : top }, 500, 'swing')
        $('body').animate({ scrollTop : top }, 500, 'swing')
      }
    }
  }

  // aux: составить id инпутфилда
  const make_name = function(model, field) {
    if (model === undefined) return field
    const res = `${model}_${field}`
    // console.log(res)
    return res
  }

  // показать текст ошибок валидации рядом с полями ввода
  const render_validation_errors = function(errors, model) {
    let $input, $error

    $.each(errors, function(field, messages) {
      if (isArray(messages)) {
        if (messages.length === 0) return

        // css-regex-selector позволит найти радио и чекбоксы, в чьи айдишники
        // которых form builder дописывает их value
        $input = $(`[id^="${make_name(model, field)}"]`)

        $error = $('<div class="invalid-feedback"></div>')
        $error.html(messages[0])
        $input.closest('.form-group').addClass('has-error').append($error)
      }
      else if (isObject(messages)) {
        $.each(messages, function(sub_model, sub_messages) {
          const sub_record = model + "_" + field + "_" + sub_model
          render_validation_errors(sub_messages, sub_record)
        })
      }
    })
  }

  $.fn.clear_previous_errors = function(){
    $('.form-group.has-error', this).each(function(){
      $('.invalid-feedback', $(this)).remove()
      $(this).removeClass('has-error')
    })
    // $.rails.enableFormElements($(this))
  }

  $.fn.init_remote = function(callbacks) {
    if (callbacks === undefined) callbacks = {}
    this.on_ajax_start(callbacks.on_start)
      .on_ajax_success(callbacks.on_success)
      .on_ajax_error(callbacks.on_error)
      .on_ajax_complete(callbacks.on_complete)
  }

  // Если сервер отвечает 500 - покажем toastr cо статус-текстом результата.
  $.fn.on_ajax_error = function(on_error) {

    // a non-idempotent request Rails changes the CSRF token and invalidates
    // the token embedded in the page meta. So that repeat non-indempotent
    // request fails if the page isn't refreshed.
    // For example, when a remote form submitted via POST fails with validation
    // errors and the form is resubmitted it fails with an InvalidAuthenticityToken error.
    // Here we send new valid token.
    this.bind('ajax:error', ({detail: [_data, status_text, {status}]}) => {
      const $form = $(this)

      if (status_text === "Unauthorized" && status === 401) {

        // делаем 1 попытку обновить csrf
        const tries = $form.data('xtries') || 0
        if (tries >= 1) {
          toastr['error'](status_text)
          return
        }
        $form.data('xtries', tries + 1)

        let token = getCookie('XSRF-TOKEN')
        if (token) {
          // обновляем токен в meta
          token = unescape(token)
          const $meta_header = $('[name="csrf-token"]')
          $meta_header.prop('content', token)

          // повторяем тот же запрос
          const $submit = $form.children().find('[type="submit"][name="commit"]')
          ButtonProgressOverlay.hide($submit)
          $submit.click()
        } else {
          toastr['error'](status_text)
        }
      }
     })

    this.bind('ajax:error', ({detail: [data, status_text, {status}]}) => {
      // это уже обработали выше
      if (status_text === "Unauthorized" && status === 401) return

      // console.log(data)
      // console.log(data.errors) // undefined || {"text":["Too many activations"]}
      // console.log(status_text) // "Bad Request" || "Internal server error"
      // console.log(status)      // 400

      this.clear_previous_errors()
      if (on_error) on_error(data, status_text, status)

      // сначала проверяем статус запроса, и если ответ в порядке, не показываем error toastr
      if (!(status >= 200 && status < 300 || status === 400)) {

        let message = dig(data, ['errors', 'text'])
        if (isNothing(message)) {
          if (isNothing(data.growl)) {
            toastr['error'](status_text)                // абстрактно объявим о случившейся проблеме
          } else {
            toastr[data.growl.type](data.growl.text)    // покажем toastr с сообщением при наличии
          }
        } else {
          toastr['warning'](message[0])                 // покажем текст ошибки
        }
      }

      // отображаем ошибки валидации под полями формы
      if (data.errors) {
        render_validation_errors(data.errors, this.data('model'))
        if (this.data('scroll2error')) scroll_to_error()
      }
    })

    return this
  }

  $.fn.on_ajax_success = function(on_success) {
    this.bind("ajax:success", ({detail: [data]}) => {
      this.clear_previous_errors()                                              // очистим форму от возможных ошибок валидации
      if (on_success) {                                                         // либо вызовем кастомный обработчик
        on_success(data)
      } else if (!isNothing(data)) {                                            // либо обработаем успех стандартно
        if (!isNothing(data.growl)) toastr[data.growl.type](data.growl.text)    // покажем toastr с сообщением об успехе
        if (data.goto_url !== undefined) {                                      // если надо, совершим редирект
          setTimeout(function() { window.location.href = data.goto_url }, 400)
        }
      }
    })
    return this
  }

  // уберём оверлей-индикатор процесса
  $.fn.on_ajax_complete = function(on_complete) {
    this.bind('ajax:complete', () => {
      ButtonProgressOverlay.hide(
        this.find('[type=submit]')
      )
      if (on_complete) on_complete()
    })
    return this
  }

  // наложим на РОДИТЕЛЯ кнопки submit формы оверлей-индикатор процесса
  $.fn.on_ajax_start = function(on_start) {
    this.bind("submit", () => {
      ButtonProgressOverlay.show(
        this.find('[type=submit]')
      )
      if (on_start) on_start()
    })
    return this
  }

}(jQuery))
