import toastr from 'toastr/toastr.js';
export { toastr };

(function() {
    $(function() {
        "use strict";

        $(document).on('click', '.toast-action', function () {
            var $this = $(this);
            toastrInit($this);
        });

        function initPageToasts() {
            $(".toast").each(function () {
                var $this = $(this);
                toastrInit($this);
            });
        }

        initPageToasts();
        document.addEventListener('DOMContentLoaded', initPageToasts);

        function defineSpecific(el) {
          if (el.hasClass('toast_sticky_warning')) {
              el.data('time-out',       0)
              el.data('close-button',   true)
              el.data('close-on-hover', false)
              el.data('toast-class',    'toast toast-sticky-warning')
              el.data('hide-duration',  500)
              el.data('tap-to-dismiss', false)
          }
        }

        function toastrInit(el) {
            if (el) {
                defineSpecific(el)

                var shortCutFunction = el.data('type');
                var msg              = el.data('message');
                var title            = el.data('title') || '';
                var $showDuration    = el.data('show-duration');
                var $hideDuration    = el.data('hide-duration');
                var $timeOut         = el.data('time-out');
                var $extendedTimeOut = el.data('extended-time-out');
                var $showEasing      = el.data('show-easing');
                var $hideEasing      = el.data('hide-easing');
                var $showMethod      = el.data('show-method');
                var $hideMethod      = el.data('hide-method');
                var $positionClass   = el.data('position-class');
                var $closeButton     = el.data('close-button');
                var $closeOnHover    = el.data('close-on-hover');
                var $target          = el.data('target')
                var $toastClass      = el.data('toast-class')
                var $tapToDismiss    = el.data('tap-to-dismiss')

                if ($showDuration) {
                    toastr.options.showDuration = $showDuration;
                }
                if ($hideDuration) {
                    toastr.options.hideDuration = $hideDuration;
                }
                if (!isNothing($timeOut)) {
                    toastr.options.timeOut = $timeOut;
                }
                if (!isNothing($extendedTimeOut)) {
                    toastr.options.extendedTimeOut = $extendedTimeOut;
                }
                if ($showEasing) {
                    toastr.options.showEasing = $showEasing;
                }
                if ($hideEasing) {
                    toastr.options.hideEasing = $hideEasing;
                }
                if ($showMethod) {
                    toastr.options.showMethod = $showMethod;
                }
                if ($hideMethod) {
                    toastr.options.hideMethod = $hideMethod;
                }
                if ($positionClass) {
                    toastr.options.positionClass = $positionClass;
                }
                if(!isNothing($closeButton)) {
                    toastr.options.closeButton = $closeButton;
                }
                if(!isNothing($closeOnHover)) {
                    toastr.options.closeOnHover = $closeOnHover;
                }
                if(!isNothing($target)) {
                    toastr.options.target = $target;
                }
                if(!isNothing($toastClass)) {
                    toastr.options.toastClass = $toastClass;
                }
                if(!isNothing($tapToDismiss)) {
                    toastr.options.tapToDismiss = $tapToDismiss;
                }

                toastr[shortCutFunction](msg, title);
            }
        }
    })
}());
