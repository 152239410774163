import * as types       from './mutation_types'
import normalizeHeaders from "../../../src/utils/normalize_headers"
import getParamByName   from "../../../src/utils/get_param_by_name"

import {
  HEADER_NEXT_PAGE,
  HEADER_PAGE,
  HEADER_PER_PAGE,
  HEADER_PREV_PAGE,
  HEADER_TOTAL,
  HEADER_TOTAL_PAGES
} from './constants'

export default {
  [types.INIT_PAGE_INFO](state) {
    const param   = getParamByName('page')
    const updates = { page: +(param || 1) }

    Object.assign(state, updates)
  },
  [types.UPDATE_PAGE_INFO](state, headers) {
    const h = normalizeHeaders(headers)

    let updates

    // Если, сериалайзер выдал одну сущность (не массив),
    // то он не добавляет в заголовки пагинацию.
    // И чтобы пагинатор не рендерился - убираем здесь пару значений.

    if (!h[HEADER_NEXT_PAGE]) {
      updates = {
        totalPages: null,
        total:      null
      }
    } else {
      updates = {
        nextPage:   Number(h[HEADER_NEXT_PAGE][0]),
        page:       Number(h[HEADER_PAGE][0]),
        perPage:    Number(h[HEADER_PER_PAGE][0]),
        prevPage:   Number(h[HEADER_PREV_PAGE][0]),
        total:      Number(h[HEADER_TOTAL][0]),
        totalPages: Number(h[HEADER_TOTAL_PAGES][0])
      }
    }

    Object.assign(state, updates)
  }
}
