// Is a given value an array?
// Borrowed from Underscore.js

export const isArray = function(val) {
  if (Array.isArray) {
    return Array.isArray(val)
  }
  return Object.prototype.toString.call(val) === '[object Array]'
}

window.isArray = isArray