import isFunction from 'lodash/isFunction'
import './index.scss'

export const Radio = {
  props: [
    'value',
    'label',
    'id',
    'name',
    'checked',
    'onChange'
  ],
  data: function() {
    return {
      innerValue: this.value
    }
  },
  methods: {
    change() {
      if (this.onChange && isFunction(this.onChange)) {
        this.onChange(this.innerValue)
      }
    }
  },
  template: `
    <div class='radio ui-radio'>
      <label class="c">
        <input type='radio' :value='innerValue' :id='id' :name="name" :checked="checked" @change="change"/>
        <span class='c ui-radio_icon'>
          <i class='icon-check' />
        </span>
        <span class='ui-radio_label'>{{label}}</span>
      </label>
    </div>
  `
}
