import AbstractController from "./abstract_controller"
import {init_submit_button} from "./tickets/index/init_submit_button"

export default class extends AbstractController {
  static targets = [ "output" ]

  index = () => {
    init_submit_button(this.view_object)
  }
}
