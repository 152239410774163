import ClipboardJS from 'clipboard/dist/clipboard.min'

document.addEventListener("DOMContentLoaded", () => {
	$('.copy-btn').click(function (e) {
		e.preventDefault();
	});

	$('[data-toggle="tooltip"]').tooltip();

	new ClipboardJS('.copy-btn').on('success', function(e) {
		$(e.trigger).tooltip('show');

		setTimeout(function() {
			$(e.trigger).tooltip('hide');
		}, 1500);

		e.clearSelection();
	});
});
