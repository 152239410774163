export default (view_ojbect) => ({
  requesting:         false,
  countries:          [],
  programs:           [],
  landings:           [],
  new_activations:    {},
  countries_url:      view_ojbect.countries_url,
  update_clicks_url:  view_ojbect.update_clicks_url,
  update_landing_url: view_ojbect.update_landing_url,
  translations:       view_ojbect.translations,
  errors:             {},
  dicts: {
    devices:          ['web', 'mobile'],
    activateds:       [true, false],
    all_countries:    view_ojbect.all_countries
  }
})