/* app/views/user_subaccounts/edit_tds/tab_content/_countries.html.slim */

import Vue            from "vue"
import Vuex           from "vuex"
import store          from './store'
import CountriesTable from './components/countries_table.vue'
import Fragment       from 'vue-fragment'

Vue.use(Fragment.Plugin)
Vue.use(Vuex)

export const init_countries = (view_object) => {
  new Vue({
    el: '#countries #el',
    store: store(view_object),
    render(h) {
      return h(CountriesTable)
    }
  })
}