import {isNothing} from "js-yaml/lib/js-yaml/common"

export const paste_timezone_to_hidden_field = () => {
  let tzone = 'nonetz'

  try {
    const format = new Intl.DateTimeFormat()
    tzone        = format.resolvedOptions().timeZone // дружит с ActiveSupport::TimeZone[tzone]
    if (isNothing(tzone) || tzone === '') throw Error('Intl failed')
  } catch {
    const client = new ClientJS()
    tzone        = client.getTimeZone()
  }

  const $input = $('#timezone')
  $input.val(tzone)
}
