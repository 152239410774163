import Vue         from 'vue'
import VueI18n     from 'vue-i18n'
import VueResource from 'vue-resource'
import {dare}      from "../../http/vue_refresh_csrf"

export const confirm_payment_link = (locale) => {
  const el = document.getElementById('updatePaymentForm')
  if (!el) return

  Vue.use(VueI18n)
  Vue.use(VueResource)
  if (!window.i18n[locale]) locale = 'en'
  const i18n = new VueI18n({ locale, messages: window.i18n })
  const data = JSON.parse(el.dataset.appData)
  new Vue({
    i18n,
    el,
    data: {
      secondsSince:          data.seconds_since,
      secondsToNextAttempts: data.seconds_to_next_attempts,
      loading:               false,
      resource:              Vue.resource('/change_payment'),
      timer:                 null,
      code:                  data.code || '',
      payType:               data.pay_type
    },
    created() {
      if (this.isDisabled) this.initTimer()
    },
    mounted() {
      if (this.isWire) this.showWire()
      OnlyEnglishHint.init()

      $('#updatePaymentForm').init_remote({
        on_error: ({ errors }) => ValidationErrors.render(errors)
      })
    },
    watch: {
      payType(newVale, oldVal) {
        if (newVale === oldVal) return

        if (newVale === 'wire') return this.showWire()
        if (oldVal === 'wire') this.hideWire()
      }
    },
    computed: {
      isWire() {
        return this.payType === 'wire'
      },
      isDisabled() {
        return this.loading || this.secondsSince < this.secondsToNextAttempts
      },
      waitingSeconds() {
        return this.secondsToNextAttempts - this.secondsSince
      },
      isValid() {
        return !!this.code
      }
    },
    methods: {
      initTimer() {
        this.timer = setInterval(
          () => {
            this.secondsSince += 1
            if (this.secondsSince === this.secondsToNextAttempts) this.clearTimer()
          },
          1000
        )
      },
      clearTimer() {
        if (!this.timer) return
        clearInterval(this.timer)
        this.timer = null
      },
      showWire() {
        $('#collapseWire').collapse('show')
      },
      hideWire() {
        $('#collapseWire').collapse('hide')
      },
      sendConfirmRequest(e) {
        if (e) e.preventDefault()
        this.loading = true

        dare(
            this.resource.save,
            this.sendConfirmRequest,
            () => {
              this.secondsSince = 1
              this.loading = false
              this.initTimer()
            },
            () => {
              this.loading = false
            },
        )
      }
    }
  })
}

// подсказку `only English alphabet characters are allowed` покажем только когда поле в фокусе
class OnlyEnglishHint {
  static init() {
    let prefixes = 'profile_beneficiary_bank_address_attributes, profile_bank_address_attributes, profile'.split(', ')
    let attrs    = 'city, address, bank_name, ben_name'.split(', ')

    let prefix, attr, field_id, $field

    for (let p = 0; p < prefixes.length; p++) {
      prefix = prefixes[p]
      for (let a = 0; a < attrs.length; a++) {
        attr = attrs[a]

        field_id = [prefix, attr].join('_')
        $field   = $('#'+field_id)

        // console.log(field_id + ' => ' + $field.length)
        if ($field.length === 0) continue

        $field.on('focus', this.onfocus.bind(this))
        $field.on('blur', this.onblur.bind(this))
      }
    }
  }

  static onfocus = (e) => this.$hint(e.target).addClass('enabled')
  static onblur  = (e) => this.$hint(e.target).removeClass('enabled')
  static $hint   = (fnode) => $(fnode).closest('div').find('small.form-text')
}

// рендер ошибок валидации вложенных моделей (в дополнение к remote_form.js)
class ValidationErrors {
  static render(errors) {
    ValidationErrors.clearPreviousErrors()
    if (!errors) return

    // console.log(JSON.stringify(errors, null, 2))
    Object.keys(errors).forEach(k => {
      const id = ValidationErrors.map[k]
      if (id) {
        let $input = $(id)
        let $error = $('<div class="invalid-feedback"></div>')
        $error.html(errors[k][0])
        $input.closest('.form-group').addClass('has-error').append($error)
      }
    })
  }

  static clearPreviousErrors() {
    Object.keys(ValidationErrors.map).forEach(k => {
      const id     = ValidationErrors.map[k]
      const $input = $(id)
      const $group = $input.closest('.form-group')
      $group.removeClass('has-error')
      $group.children('.invalid-feedback').remove()
    })
  }
}

// ActiveRecord validation error key -> input field id
ValidationErrors.map = {
  'beneficiary_bank_address.country': '#profile_beneficiary_bank_address_attributes_country_id',
  'beneficiary_bank_address.city':    '#profile_beneficiary_bank_address_attributes_city',
  'beneficiary_bank_address.address': '#profile_beneficiary_bank_address_attributes_address',
  'beneficiary_bank_address.zipcode': '#profile_beneficiary_bank_address_attributes_zipcode',

  'bank_address.country': '#profile_bank_address_attributes_country_id',
  'bank_address.city':    '#profile_bank_address_attributes_city',
  'bank_address.address': '#profile_bank_address_attributes_address',
  'bank_address.zipcode': '#profile_bank_address_attributes_zipcode',
}