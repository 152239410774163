<script>

  import ClicksForm   from './components/ClicksForm/clicks_form'
  import LandingsForm from './components/LandingsForm/landings_form'

  export default {
    name: 'ActivatedProgramRow',
    props: {
      country_id: { type: Number,  required: true },
      program:    { type: Object,  required: true },
      landings:   { type: Array,   required: true },
      device:     { type: String,  required: true },
    },
    components: { ClicksForm, LandingsForm },
    computed: {
      is_tds_setting_active() {
        // return this.program.status === 0
        return false
      },
      row_css_class() {
        let res = `table-${this.is_active ? 'danger' : 'success'}`
        if (!!this.program.just_activated) res += ' blink'
        return res
      },
      landing_collapse_id() {
        return `landings_${this.country_id}_${this.device}_${this.program.program_id}`
      },
      is_any_landing() {
        return this.landings.length > 0
      }
    }
  }
</script>

<template>
  <fragment>
    <tr :class="row_css_class">
      <clicks-form
          :country_id             = "country_id"
          :program_id             = "program.program_id"
          :program_clicks         = "program.clicks"
          :device                 = "device"
          :is_tds_setting_active  = "is_tds_setting_active">
      </clicks-form>

      <td>
        <strong>
          {{ program.name }}
          <a v-if="is_any_landing" class="btn btn-info btn-sm float-right ml-1" role="button" data-toggle="collapse" :href="`#${landing_collapse_id}`" aria-expanded="false" aria-controls="collapse">Landings</a>
          <small v-if="is_tds_setting_active" class="text-danger float-right d-inline-block">On pause</small>
        </strong>
      </td>

      <td><p>{{ program.category_title }}</p></td>
      <td><p style="font-size: 18px">{{ program.profit }}</p></td>
    </tr>
    <tr class="collapse" :id="landing_collapse_id">
      <td colspan="4">
        <landings-form :landings="landings" :program="program" :device="device" :country_id="country_id"></landings-form>
      </td>
    </tr>
  </fragment>
</template>