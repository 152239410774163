import AbstractController        from "./abstract_controller"
import {init_subaccounts_filter} from "./user_subaccounts/subaccounts_filter"
import {init_smartlink_form}     from "./user_subaccounts/new_smartlink"
import {init_edit_smartlink}     from "./user_subaccounts/edit_smartlink"
import {init_edit_subaccount}    from "./user_subaccounts/edit_subaccount"
import {init_edit_tds}           from "./user_subaccounts/edit_tds"

export default class extends AbstractController {
  static targets = [ "output" ]

  new_smartlink   = () => init_smartlink_form(this.view_object)
  subaccounts     = () => init_subaccounts_filter(this.view_object.program_status_options)
  edit_smartlink  = () => init_edit_smartlink(this.view_object)
  edit_subaccount = () => init_edit_subaccount(this.view_object)
  edit_tds        = () => init_edit_tds(this.view_object)
}
