// Клик по кнопке "add Trafficback" добавляет строку с компонентом +tpl_traffic_back+,
// с помощью которого можно указать `*_traffic_backs_attributes`.
// str_html_template = app/views/user_subaccounts/_traffic_back.html.slim

export const init_add_traffic_back = (str_html_template) => {
  $('.more_traffic_backs').bind('click', function (e) {
    const type = $(e.target).data('type');
    const timestamp = Date.now();
    const html = str_html_template.replace(/idplaceholder/g, timestamp).replace(/typeplaceholder/g, type);

    $(`#${type}_traffic_backs`).append(html);

    // для несуществующих записей клик по чекбоксу "destroy" удалит этот html-узел из формы
    $(`#${type}_traffic_backs`).find('[type="checkbox"]').bind('click', function(e) {
      const $cb       = $(e.target)
      const persisted = $cb.data('persisted')

      if (!persisted) {
        $cb.closest('.traffic_back_row').remove()
      }
    })

    e.preventDefault();
  })
}

const FIELDS = 'web_traffic_backs wap_traffic_backs'.split(' ')

// трансформируем строку только-что созданной записи - добавляем скрытый инпут с id, меняем временный id на постоянный у всех элементов
export const handle_traffic_back_response = function(data_user_subaccount) {
  // перебираем результат
  for (let f = 0; f < FIELDS.length; f++) {
    let field = FIELDS[f]
    let tbs   = data_user_subaccount[field]

    if (!isNothing(tbs)) {
      for (let i = 0 ; i < tbs.length; i++) {
        let tb = tbs[i]

        if (!isNothing(tb.tmp_id)) {
          let $cb          = $(`#user_subaccount_${field}_attributes_${tb.tmp_id}__destroy`)
          let $cb_fieldset = $(`fieldset.user_subaccount_${field}_${tb.tmp_id}__destroy`)
          let $cb_label    = $cb_fieldset.find('.custom-control-label')
          let $hidden_cb   = $(`input[name='user_subaccount[${field}_attributes][${tb.tmp_id}][_destroy]'][type='hidden']`)
          let $url         = $(`#user_subaccount_${field}_attributes_${tb.tmp_id}_url`)
          let $hidden_type = $(`input[name='user_subaccount[${field}_attributes][${tb.tmp_id}][type_id]']`)
          let $country     = $(`select[name='user_subaccount[${field}_attributes][${tb.tmp_id}][country_id]']`)

          $cb.data('persisted', true)
          $cb.prop('name', $cb.prop('name').split(tb.tmp_id).join(tb.id))
          $cb.prop('id',   $cb.prop('id').split(tb.tmp_id).join(tb.id))
          $cb.data('id',   tb.id)

          $cb_fieldset.removeClass(`user_subaccount_${field}_${tb.tmp_id}__destroy`)
          $cb_fieldset.addClass(`user_subaccount_${field}_${tb.id}__destroy`)

          $cb_label.prop('for', `user_subaccount_${field}_attributes_${tb.id}__destroy`)

          $hidden_cb.prop('name', $hidden_cb.prop('name').split(tb.tmp_id).join(tb.id))

          $url.prop('name', $url.prop('name').split(tb.tmp_id).join(tb.id))
          $url.prop('id', $url.prop('id').split(tb.tmp_id).join(tb.id))

          $hidden_type.prop('name', $hidden_type.prop('name').split(tb.tmp_id).join(tb.id))
          $hidden_type.prop('id', $hidden_type.prop('id').split(tb.tmp_id).join(tb.id))

          $country.prop('name', $country.prop('name').split(tb.tmp_id).join(tb.id))
          $country.prop('id', $country.prop('id').split(tb.tmp_id).join(tb.id))

          $cb
              .closest('.traffic_back_row')
              .append($(`<input class="form-control is-valid hidden" type="hidden" value="${tb.id}" name="user_subaccount[${field}_attributes][${tb.id}][id]" id="user_subaccount_${field}_attributes_${tb.id}_id">`))
        }
      }
    }
  }
}

// удаляем из формы строки тех записей, которые только-что были удалены чекбоксом "delete"
export const handle_del_traffic_back_response = function(data_user_subaccount) {
  let to_del
  for (let f = 0; f < FIELDS.length; f++) {
    to_del = []
    let field = FIELDS[f]
    let $cbs  = $(`input.boolean[id^=\'user_subaccount_${field}_attributes_\']`)

    // перебираем чекбоксы "delete"
    $cbs.each(function() {
      let $cb     = $(this)
      let id      = $cb.data('id')
      let deleted = true

      // ищем id в данных, что только что пришли с сервера
      let tbs   = data_user_subaccount[field]
      if (!isNothing(tbs)) {
        for (let i = 0 ; i < tbs.length; i++) {
          let tb = tbs[i]
          // если не нашли - отмечаем как "к удалению"
          if (+tb.id === +id) {
            deleted = false
            break
          }
        }
      }

      if (deleted) {
        to_del.push(id)
      }
    })

    // если есть что удалить - удаляем строку, содержащую удалённый traffic back
    if (to_del.length > 0) {
      for (let i = 0; i < to_del.length; i++) {
        let $cb = $(`#user_subaccount_${field}_attributes_${to_del[i]}__destroy`)
        $cb.closest('.traffic_back_row').remove()
      }
    }
  }
}

// в некоторых полях отобразим значения, которые приняли они после записи данных в базу
export const handle_changes = function(data_user_subaccount) {
  const fields = 'traffic_back_proxy,traffic_back_wap,traffic_back_web'.split(',')
  for (let f = 0; f < fields.length; f++) {
    let field = fields[f]
    let $input = $(`#user_subaccount_${field}`)
    let val    = data_user_subaccount[field]
    $input.val(val)
  }
}