/*
HTTP/2 200 OK
cache-control: no-cache
content-length: 1103
content-type: application/json
date: Mon, 18 Jan 2021 09:43:18 GMT
link: <https://app.paysale.com/api/transactions?page=1&per_page=30>; rel="prev", <https://app.paysale.com/api/transactions?page=3&per_page=3>; rel="next", <https://app.paysale.com/api/transactions?page=1&per_page=3>; rel="first", <https://app.paysale.com/api/transactions?page=3&per_page=3>; rel="last"
status: 200 OK
vary: Origin
x-next-page: 3
x-page: 2
x-per-page: 3
x-prev-page: 1
x-total: 8
x-total-pages: 3

|-----------------|-------------------------------------------------|
| Header          | Description                                     |
|-----------------|-------------------------------------------------|
| `x-next-page`   | The index of the next page.                     |
| `x-page`        | The index of the current page (starting at 1).  |
| `x-per-page`    | The number of items per page.                   |
| `x-prev-page`   | The index of the previous page.                 |
| `x-total`       | The total number of items.                      |
| `x-total-pages` | The total number of pages.                      |
|-----------------|-------------------------------------------------|
*/

export const HEADER_NEXT_PAGE   = 'X-NEXT-PAGE'
export const HEADER_PAGE        = 'X-PAGE'
export const HEADER_PER_PAGE    = 'X-PER-PAGE'
export const HEADER_PREV_PAGE   = 'X-PREV-PAGE'
export const HEADER_TOTAL       = 'X-TOTAL'
export const HEADER_TOTAL_PAGES = 'X-TOTAL-PAGES'