export const FORMAT_AR = 'DD-MM-YYYY'
export const FORMAT_BN = 'DD/MM/YYYY'
export const FORMAT_EN = 'YYYY-MM-DD'
export const FORMAT_ES = 'DD/MM/YYYY'
export const FORMAT_FR = 'DD/MM/YYYY'
export const FORMAT_RU = 'MM.DD.YYYY'
export const FORMAT_SERVER = 'YYYY.MM.DD'

export const ALL = {
  ar: FORMAT_AR,
  bn: FORMAT_BN,
  en: FORMAT_EN,
  es: FORMAT_ES,
  fr: FORMAT_FR,
  ru: FORMAT_RU
}