import Vue               from 'vue'
import Vuex              from 'vuex'
import TransactionsTable from './components/transactions_table.vue'
import store             from './store'

Vue.use(Vuex)

export const init_index = ({ translations, transaction_types }) => {
  new Vue({
    el: '#controller',
    store,
    render(h) {
      return h(TransactionsTable, { props: { translations, transaction_types } })
    }
  })
}