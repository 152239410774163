// listen submit button:
//   • the first click opens form
//   • the second click submits form
export const init_submit_button = () => {
  const $form = $('.form-add-tickets .hide-me')
  const $btn  = $('#btn-create-ticket')
  const $sub  = $('[name="ticket[title]"]')

  $form.hide()

  if ($btn.hasClass('open')) {
    $form.show()
  }

  if ($sub.hasClass('subjed')) {
    $sub.focus()
  }

  $btn.click(function () {
    $(this).toggleClass('open')

    if ($(this).hasClass('open'))
      $form.show()
    else
      $('.form-add-tickets').submit()

    return false
  })

  $(".feedback__importance label").click(function () {
    $(".feedback__importance label").removeClass("active")

    $(this).addClass("active")
  })
}
