<script>
  import Vue             from "vue"
  import { mapState }    from 'vuex'
  import VueSelect       from "vue-select"
  import getParamByName  from "../../../../../../src/utils/get_param_by_name"


  Vue.component('v-select', VueSelect)

  const NAME       = 'CountriesFilter'
  const FIELD_NAME = 'country_id'

  export default {
    name:  NAME,
    props: { },
    mounted() {
      this.parseQuery()
    },
    computed: {
      ...mapState([
        'dicts',
        'filters'
      ]),
      selectedOption/*Object{id,label}*/: {
        get() {
          return this.find(this.filters[FIELD_NAME])
        },
        set(v) {
          this.$store.dispatch('filters/updateFilter', { name: FIELD_NAME, value: (v ? v.id : v) /*when clear then v = undefined*/ })
          this.$store.dispatch('fetchCountries')
        }
      },
    },
    methods: {
      parseQuery() {
        const incoming = getParamByName(FIELD_NAME)
        this.$store.dispatch('filters/initQueryParsedFilter', { name: FIELD_NAME, value: incoming })
      },
      find(country_id) {
        return this.dicts.all_countries.find(x => x.id == country_id)
      },
      placeholder() {
        return 'Select country'
      }
    }
  }
</script>

<template>
  <div class="col-xl-4 col-lg-6 col-md-12 mb-3 p-0">
    <v-select
        :options     = 'dicts.all_countries'
        v-model      = 'selectedOption'
        :placeholder = "placeholder()"
        :clearable   = 'true'
    ></v-select>
  </div>
</template>