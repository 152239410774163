import Vue from 'vue';
import VueSelect from 'vue-select';
import VueResource from 'vue-resource';

export const init_subaccounts_filter = (pstatus_options) => {
  Vue.use(VueResource)
  Vue.component('v-select', VueSelect)

  // noinspection JSUnusedGlobalSymbols
  new Vue({
    el:      '#subaccounts_filter',
    data:    {
      filters: {
        programs:       [],  // офферы
        program_status: [],  // статусы офферов
        off:            ''   // статус субаккаунта
      },
      options: {
        programs:       [],
        program_status: pstatus_options
      }
    },
    created: function() {
      this.fetchPrograms()
    },
    mounted: function() {

    },
    updated: function() {
      $('input#program_id').val(this.prepareFilters(this.filters.programs))
      $('input#off').val(this.filters.off)
      $('input#program_status').val(this.prepareFilters(this.filters.program_status))
    },
    methods: {
      fetchPrograms: function() {
        // в VueSelect программ подгружает список программ, которые есть у вебмастера
        this.$http.get('/user/stats/programs').then((function(resp) {
          this.options.programs = resp.body
          this.handleIncomingParams()
        }), function(resp) {})
      },
      prepareFilters: function(flt) {
        // в скрытом поле `#program_id` содержатся id программ через запятую,
        // в таком виде они и уходят на сервер при GET-сабмите формы
        let res = []
        for (let i = 0; i < flt.length; i++) {
          res.push(flt[i].id)
        }
        return res.join(',')
      },
      handleIncomingParams: function() {
        const incoming_params = JSON.parse(this.$el.dataset.incomingParams)
        'Programs,Url,Off,ProgramStatus'.split(',').forEach(filter => this[`handleIncomingParams${filter}`](incoming_params))
      },
      handleIncomingParamsPrograms: function(incoming_params) {
        // считываем входящие параметры и выставляем значения в селекте
        if (!incoming_params.program_id || !incoming_params.program_id.length) return
        const params_program_id = incoming_params.program_id.split(',')
        for (let i = 0, program; i < this.options.programs.length; i++) {
          program = this.options.programs[i]
          if (params_program_id.includes(""+program.id)) this.filters.programs.push(program)
        }
      },
      handleIncomingParamsUrl: function(incoming_params) {
        // считываем входящие параметры и выставляем значения в инпуте поиска по url
        if (!incoming_params.url) return
        $('input#url').val(incoming_params.url)
      },
      handleIncomingParamsOff: function (incoming_params) {
        // считываем входящие параметры и выставляем соответствующий radio-фильтр "статус ссылки"
        if (incoming_params.off === undefined) return
        this.filters.off = incoming_params.off
      },
      handleIncomingParamsProgramStatus: function(incoming_params) {
        // считываем входящие параметры и выставляем значения в селекте «Program Status»
        if (!incoming_params.program_status || !incoming_params.program_status.length) return
        for (let i = 0, pstatus; i < this.options.program_status.length; i++) {
          pstatus = this.options.program_status[i]
          if (incoming_params.program_status.includes(pstatus.id)) this.filters.program_status.push(pstatus)
        }
      }
    }
  })
}
