/* app/views/user_subaccounts/edit_tds.html.slim */

import {init_common_form}          from "./edit_tds/init_common_form"
import {init_try_postback_feature} from "./edit_subaccount/try_postback_url"
import {init_activation_form}      from '../programs/init_activation_form'
import {init_countries}            from './edit_tds/init_countries'
import {init_postbacks_form}       from "./edit_subaccount/postbacks_form"
import {tabs_init}                 from "../registrations/tabs"

export const init_edit_tds = (view_object) => {
  init_common_form(view_object)
  init_try_postback_feature(view_object)
  init_postbacks_form()
  init_countries(view_object)
  init_activation_form(function () {
    window.onActivateOfferSent()
  })
  tabs_init()
}