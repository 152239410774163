// Is a given value equal to null?
window.isNull = function(obj) {
  return obj === null;
};

// Is a given variable undefined?
window.isUndefined = function(obj) {
  return obj === void 0;
};

window.isNothing = function(obj) {
  return isNull(obj) || isUndefined(obj);
};
