export const buildFilenameFromObjectValues = function (object = {}) {
  let filename = []

  for (let key in object) {
    filename.push(object[key])
  }

  filename =
    filename
      .join(JOINER)
      .toLowerCase()
      .replace(KEEP_ONLY_LATIN, REPLACE_WITH)

  return filename
}

const KEEP_ONLY_LATIN = /[\W]/g
const REPLACE_WITH    = '_'
const JOINER          = '_'