
require('./javascripts/bootstrap');
require('./javascripts/mega-dropdown');
// require('./javascripts/theme-settings.js');

require('./libs/popper/popper');
require('./libs/moment/moment');
require('./libs/select2/select2');

import './main'
