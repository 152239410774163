// import { s__ } from '~/locale';

export const PAGINATION_UI_BUTTON_LIMIT = 4
export const UI_LIMIT                   = 6
export const SPREAD                     = '...'
export const PREV                       = 'Prev'
export const NEXT                       = 'Next'
export const FIRST                      = '« First'
export const LAST                       = 'Last »'
export const LABEL_FIRST_PAGE           = 'Go to first page'
export const LABEL_PREV_PAGE            = 'o to previous page'
export const LABEL_NEXT_PAGE            = 'Go to next page'
export const LABEL_LAST_PAGE            = 'Go to last page'
