import * as types             from './mutation_types'
import {decorateTransactions} from "./decorators"
import {decorateSummary}      from "./decorators"

export default {
  [types.REQUEST_TRANSACTIONS](state) {
    Object.assign(state, {
      transactionsFetchInProgress: true
    })
  },
  [types.RECEIVE_TRANSACTIONS_SUCCESS](state, transactions) {
    if (!transactions) {
      console.warn("Server answer is wrong, repeat the spec.")
      return
    }

    Object.assign(state, {
      transactionsFetchInProgress: false,
      transactions: decorateTransactions(transactions)
    })
  },
  [types.REQUEST_SUMMARY](state) {
    Object.assign(state, {
      summaryFetchInProgress: true
    })
  },
  [types.RECEIVE_SUMMARY_SUCCESS](state, summary) {
    if (!summary) {
      console.warn("Server answer is wrong, repeat the spec.")
      return
    }

    Object.assign(state, {
      summaryFetchInProgress: false,
      summary: decorateSummary(summary)
    })
  },
  [types.REQUEST_XLS](state) {
    Object.assign(state, {
      transactionsFetchInProgress: true
    })
  },
  [types.RECEIVE_XLS_SUCCESS](state) {
    Object.assign(state, {
      transactionsFetchInProgress: false
    })
  },
  [types.REGISTER_FILTERS_COMPONENTS](state, allFiltersNames) {
    Object.assign(state, { registeredFilters: allFiltersNames })
  }
}