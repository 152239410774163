import Vue from 'vue'

import VueResource    from 'vue-resource'
import {downloadBlob} from "../../../src/utils/download_blob"
Vue.use(VueResource)

export default (filename, query) => {
  return Vue
    .http.get(`/api/transactions/export.xlsx?${query}`, { responseType: 'blob' })
      .then(response => response.blob())
      .then(blob => downloadBlob(blob, `transactions_${filename}.xlsx`))
}