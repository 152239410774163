import * as types from './mutation_types'
import index      from '../api/index'
import summary    from '../api/summary'
import export_xls from '../api/export'

import {buildFilenameFromObjectValues} from "../../../src/utils/build_filename_from_object_values"
import {objectToQueryStringOmit}       from "../../../src/utils/object_to_query_string"
import {historyPushStateCurrentUrl}    from "../../../src/utils/history_push_state" /*[h]*/

export default {
  fetchTransactions({ dispatch, commit, state }, params) {
    dispatch('pageInfo/initPageInfo')
    commit(types.REQUEST_TRANSACTIONS)
    const newParams = !params ? { page: state.pageInfo.page } : params
    return index
        .query({...newParams, ...state.filters})
        .then(({ data, headers }) => {
          // console.log(JSON.stringify(headers, null, 2))
          // console.log(JSON.stringify(data.result, null, 2))
          dispatch('pageInfo/updatePageInfo', headers)
          commit(types.RECEIVE_TRANSACTIONS_SUCCESS, data.result)
        })
  },

  fetchSummary({ dispatch, commit, state }) {
    commit(types.REQUEST_SUMMARY)
    return summary
        .query({...state.filters})
        .then(({ data }) => {
          commit(types.RECEIVE_SUMMARY_SUCCESS, data.result)
        })
  },

  fetchExport({ dispatch, commit, state }) {
    commit(types.REQUEST_XLS)
    return export_xls(
        buildFilenameFromObjectValues(state.filters),
        objectToQueryStringOmit(state.filters)
    ).then(() => {
      commit(types.RECEIVE_XLS_SUCCESS)
    })
  },

  updateHistory({ dispatch, state }, { page, filters }) {
    const newFilters = !filters ? state.filters : filters
    historyPushStateCurrentUrl({page, ...newFilters}) /*[h]*/
  },

  fetchInitTransactions({ dispatch, state }, filters) {
    dispatch('pageInfo/initPageInfo')
    dispatch('filters/updateFilters', filters)

    dispatch('updateHistory', { page: state.pageInfo.page, filters: filters })
    dispatch('fetchTransactions', { page: state.pageInfo.page }).then(() => dispatch('fetchSummary'))
  },

  bindHistoryEvents: function(_, comp) { /*[h]*/
    window.addEventListener('popstate', (e) => {
      // if event is from Paginator component (historyPushState)
      if (e.state.url) {
        e.preventDefault()
        comp.$root.$emit('popstate')
      }
    })
  },

  filterTransactions: function({ dispatch, state}, { comp, newFilters, pushState }) {
    if (!isObjectsEqual(newFilters, state.filters)) {
      dispatch('filters/updateFilters', newFilters)
      dispatch('fetchSummary')
    }

    if (pushState) {
      // nominal behavior - user changes filters
      dispatch('updateHistory', { page: 1 })
      dispatch('fetchTransactions', { page: 1 })
    } else {
      // if auto-submit on popstate
      dispatch('fetchTransactions')
    }
  },

  resetFiltersAndPagination: function({ dispatch, state }, comp) {
    state.registeredFilters.map(
        (filterName) => comp.$root.$emit(`${filterName}/reset`)
    )

    const nullFilters = { order_id: null, type_id: null }
    dispatch('filters/updateFilters', nullFilters)
    dispatch('fetchSummary')
    dispatch('updateHistory', { page: 1 })
    dispatch('fetchTransactions', { page: 1 })
  },

  // Каждый фильтр-компонент регистрируется здесь, чтобы ему можно было отправить event по его имени
  registerFilters: function({ commit }, allFiltersNames) {
    commit(types.REGISTER_FILTERS_COMPONENTS, allFiltersNames)
  }
}