/**
 this will take in the `name` of the param you want to parse in the url
 if the name does not exist this function will return `null`
 otherwise it will return the value of the param key provided
 */
export default (name, urlToParse) => {
  const url = urlToParse || window.location.href;
  const parsedName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${parsedName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};