/**
 * Сортировка массива объектов +ary+ по ключу +prop+.
 */
window.arraySortBy = (function() {
  const sorters = {
    string: function(a, b) {
      if (a < b) {
        return -1
      } else if (a > b) {
        return 1
      } else {
        return 0
      }
    },

    number: function(a, b) {
      return a - b
    }
  }

  return function(ary, prop) {
    let type = typeof ary[0][prop] || 'string'
    return ary.sort(function(a, b) {
      return sorters[type](a[prop], b[prop])
    })
  }
})()

/* Сортировка массива:
let arr = []
arr.push({ name: 'asdf', id: 5 })
arr.push({ name: 'qwerty', id: 1 })
arr.push({ name: 'zxcv', id: 3 })
arraySortBy(arr, 'id')
arraySortBy(arr, 'name')
 */

/* Сортировка хэша:
let hash = {}
hash['asdf'] = { name: 'asdf', id: 5 }
hash['qwerty'] = { name: 'qwerty', id: 1 }
hash['zxcv'] = { name: 'zxcv', id: 3 }
arraySortBy(Object.values(hash), 'id')
arraySortBy(Object.values(hash), 'name')
 */
