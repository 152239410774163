window.isObjectsEqual = function(a1, a2){
  for (var p in a1) {
    if(typeof(a1[p]) !== typeof(a2[p])) return false;
    if((a1[p]===null) !== (a2[p]===null)) return false;
    switch (typeof(a1[p])) {
      case 'undefined':
        if (typeof(a2[p]) != 'undefined') return false;
        break;
      case 'object':
        if(a1[p]!==null && a2[p]!==null && (a1[p].constructor.toString() !== a2[p].constructor.toString() || !a1[p].equals(a2[p]))) return false;
        break;
      case 'function':
        if (p != 'equals' && a1[p].toString() != a2[p].toString()) return false;
        break;
      default:
        if (a1[p] !== a2[p]) return false;
    }
  }
  return true;
}

/*
var a = {a: 'text', b:[0,1]};
var b = {a: 'text', b:[0,1]};
var c = {a: 'text', b: 0};
var d = {a: 'text', b: false};
var e = {a: 'text', b:[1,0]};
var f = {a: 'text', b:[1,0], f: function(){ this.f = this.b; }};
var g = {a: 'text', b:[1,0], f: function(){ this.f = this.b; }};
var h = {a: 'text', b:[1,0], f: function(){ this.a = this.b; }};
var i = {
    a: 'text',
    c: {
        b: [1, 0],
        f: function(){
            this.a = this.b;
        }
    }
};
var j = {
    a: 'text',
    c: {
        b: [1, 0],
        f: function(){
            this.a = this.b;
        }
    }
};
var k = {a: 'text', b: null};
var l = {a: 'text', b: undefined};

isObjectsEqual(a, b);  // expected true;  returned true
isObjectsEqual(a, c);  // expected false; returned false
isObjectsEqual(c, d);  // expected false; returned false
isObjectsEqual(a, e);  // expected false; returned false
isObjectsEqual(f, g);  // expected true;  returned true
isObjectsEqual(h, g);  // expected false; returned false
isObjectsEqual(i, j);  // expected true;  returned true
isObjectsEqual(d, k);  // expected false; returned false
isObjectsEqual(k, l);  // expected false; returned false

 */