/**
 * If text content doesn't contain any word breaks
 * this function adds style `word-break: break-all;`
 * to these elements to avoid markup artifacts.
 *
 * ::Example::
 *
 * <td class="check-word-break">..some user generated content...</td>
 *
 * ::Depends::
 *
 * check-word-break.scss
 *
*/

const rx            = /[ \-]/
const initial_klass = 'check-word-break'
const klass_breaker = 'word-break'

const startCheckWordBreak = function () {
  $(`.${initial_klass}`).each(function() {
    const $this = $(this)
    $(this).removeClass(initial_klass)
    // console.log($this.text(), rx.test($this.text()))
    if (!rx.test($this.text())) $this.addClass(klass_breaker)
  })
}

document.addEventListener("DOMContentLoaded", startCheckWordBreak)