// views/devise/sessions/new.html.slim
// views/devise/registrations/new.html.slim

import { Controller }                     from "stimulus"
import { paste_timezone_to_hidden_field } from "./registrations/paste_timezone_to_hidden_field"
import {init_adv_form}                    from "./sessions/adv_form"
import {init_aff_form}                    from "./sessions/aff_form"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    // настроим форму "регистрации" рекла
    if (this.incoming_params && this.incoming_params.advertiser === 'true') init_adv_form()

    // настроим форму "регистрации" аффилиата
    if (this.incoming_params && (this.incoming_params.advertiser === 'false' || !this.incoming_params.advertiser)) init_aff_form()

    // определит TimeZone и впишет в hidden field #timezone
    paste_timezone_to_hidden_field()
  }

  // парсим входящие query-параметры
  get incoming_params() {
    const js_root = $('.js[data-controller=sessions]')
    return js_root.data('incomingParams')
  }

}
