export const omitNullAndUndefined = (params = {}) => {
  let result = {}

  let key, value
  for (key in params) {
    value = params[key]
    if (value === undefined ||
        value === null ||
        value === 'undefined' ||
        value === 'null') continue
    result[key] = value
  }

  return result
}