import Vue       from 'vue'
import Vuex      from 'vuex'
import actions   from './actions'
import mutations from './mutations'
import state     from './state'
import pageInfo  from '../../../stores/modules/page_info'
import filters   from '../../../stores/modules/filters'

Vue.use(Vuex)

export const createStore = () => ({
  actions,
  mutations,
  state: state(),
  modules: {
    pageInfo: pageInfo,
    filters:  filters
  }
})

export default new Vuex.Store(createStore())
