import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    const links = $('.nav a.nav-link', this.element);
    this.links = links;

    function hideAreaControls() {
      const control = $(this).attr('aria-controls');
      $(`#${control}`).removeClass('active show')
    }

    function showAreaControls() {
      const control = $(this).attr('aria-controls');
      $(`#${control}`).addClass('active show')
    }

    links.on('hide.bs.tab', hideAreaControls)
    links.on('show.bs.tab', showAreaControls)
  }

  disconnect() {
    this.links.off('click.navTab')
  }
}
