<script>
  import { mapState, mapActions } from 'vuex'
  import TablePagination          from "../../../../src/components/TablePagination/table_pagination"
  import ProgramRow               from "./components/ProgramRow/program_row"
  import CountriesFilter          from "./components/CountriesFilter/countries_filter"

  export default {
    name: 'CountriesTable',
    props: {
    },
    components: { TablePagination, ProgramRow, CountriesFilter },
    computed: {
      ...mapState([
        'pageInfo',
        'countries',
        'programs',
        'landings',
        'new_activations',
        'dicts',
        'translations'
      ])
    },
    mounted() {
      this.$store.dispatch('initialize')
    },
    methods: {
      ...mapActions(['fetchCountries']),
      onChangePage: function(page) {
        this.fetchCountries({ page })
      },
      program_landings: function(program) {
        return this.landings.filter(landing => landing.program_id === program.program_id)
      },
      percentsEvenly: function(rkey) {
        const inputs  = $(`[name^="${rkey}"]`)
        const percent = (100 / inputs.length).toFixed(0)
        const that    = this
        $(inputs).each(function () {
          that.$root.$emit('evenly', {rkey, percent})
        })
      }
    }
  }
</script>

<template>
  <div>
    <countries-filter></countries-filter>
    <table-pagination :page-info="pageInfo" :change="onChangePage"></table-pagination>
    <table v-for="country in programs" class="table table-bordered table-hover country_table" :key="`country_${country.id}`">
      <tr class="table-warning">
        <td colspan="4">
          <h4 class="mb-0 country_title">{{ country.title }}</h4>
        </td>
      </tr>

      <fragment v-for="device in dicts.devices" v-if="country[device]" :key="`country_${country.id}_${device}`">
        <tr>
          <td>
            <p class='device' style="font-size: 14px">{{ country[device].title }}</p>
          </td>
          <td colspan="3">
            <a class="btn btn-primary btn-small percents" @click="percentsEvenly(`${country.id}_${device}`)">{{ translations.percents_evenly }}</a>
          </td>
        </tr>
        <fragment v-for="activated in dicts.activateds" :key="`country_${country.id}_${device}_${activated}`">
          <program-row
              v-for            = "program in country[device][activated]"
              :key             = "`country_${country.id}_${device}_${activated}_${program.program_id}`"
              :country_id      = "country.id"
              :program         = "program"
              :device          = "device"
              :activated       = "activated"
              :landings        = "program_landings(program)"
              :new_activations = "new_activations"
          ></program-row>
        </fragment>
      </fragment>
    </table>
    <table-pagination :page-info="pageInfo" :change="onChangePage"></table-pagination>
  </div>
</template>