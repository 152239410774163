import {convertToSnakeCase} from "../text/convert_to_snake_case"

/** Returns the all child components names.
 *
 * @param [VueComponent] comp
 * @param [Boolean]      doConvertToSnakeCase
 *
 * @returns Array<String>
 */

export const getChildrenNames = (comp, doConvertToSnakeCase = false) => {
    return comp.$children.map(
        (e) => {
            let component_name = e.$vnode.tag.split('-').pop()
            return (doConvertToSnakeCase ? convertToSnakeCase(component_name) : component_name)
        }
    )
}

// this.$children.map((e) => console.log(e.$vnode.tag))
//
//   => vue-component-1406-FilterDateRange
//   => vue-component-1407-FilterTransactionType
//   => vue-component-1408-FilterOrderId
//   => vue-component-1409-TableLoading