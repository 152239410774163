import * as types from './mutation_types'
import Vue        from "vue"

export default {
  [types.REQUEST_COUNTRIES](state) {
    Object.assign(state, {
      requesting: true
    })
  },
  [types.REQUEST_COUNTRIES_SUCCESS](state, { countries, programs, landings, new_activations }) {
    Object.assign(state, {
      requesting:      false,
      countries:       countries,
      programs:        programs,
      landings:        landings,
      new_activations: new_activations
    })
  },
  [types.REQUEST_COUNTRIES_FAIL](state) {
    Object.assign(state, {
      requesting: false
    })
  },



  [types.ACTIVATE_PROGRAM](state) {
  },
  [types.ACTIVATE_PROGRAM_SUCCESS](state, { country_id, device, program_id }) {
    Vue.set(state.programs[country_id][device][false][program_id], 'just_activated', true)
  },
  [types.ACTIVATE_PROGRAM_FAIL](state) {
  },



  [types.UPDATE_CLICKS](state) {
  },
  [types.UPDATE_CLICKS_SUCCESS](state) {
    Vue.set(state, 'errors', {})
  },
  [types.UPDATE_CLICKS_FAIL](state, { country_id, device, program_id, errors }) {
    let newErrors = {
      updateClicks: {
        [country_id]: {
          [device]: {
            [program_id]: errors
          }
        }
      }
    }

    Vue.set(state, 'errors', newErrors)
  },



  [types.UPDATE_LANDING](state) {
  },
  [types.UPDATE_LANDING_SUCCESS](state) {
    Vue.set(state, 'errors', {})
  },
  [types.UPDATE_LANDING_FAIL](state, { country_id, device, program_id, errors }) {
    let newErrors = {
      updateLanding: {
        [country_id]: {
          [device]: {
            [program_id]: errors
          }
        }
      }
    }
    Vue.set(state, 'errors', newErrors)
  },



  [types.MAKE_OFFER_JUST_ACTIVATED](state, { program_id }) {
    Vue.set(state, 'new_activations', {...state.new_activations, ...{[program_id]: true}})
  }
}