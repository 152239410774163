/* app/views/programs/_activate_offer_ticket_modal.html.slim */

import Vue                            from "vue"
import show_simple_modal              from "../../src/components/show_simple_modal"
import VueSelect                      from "vue-select"
import {vselect_init, vselect_update} from "../../src/components/v_select"

export const init_activation_form = (onSuccess) => {
  Vue.component('v-select', VueSelect)

  new Vue({
    el: '#activateOfferTicketModal',
    data: {
      traffic_sources: [],
      options: { // of {label,id}
        traffic_sources: []
      }
    },
    mounted: function () {
      this.init_form()
      this.vselect_mounted()
    },
    updated: function () {
      this.vselect_updated()
    },
    methods: {
      init_form: function () {
        $('#activateOfferTicketModal form').init_remote({
          on_success: ({goto_url, growl: { text }}) => {
            $('#activateOfferTicketModal').modal('hide')
            show_simple_modal(text, 'Success', 'OK', () => {
              if (typeof onSuccess === 'function') {
                onSuccess()
              } else {
                window.location.href = goto_url
              }
            })
          }
        })
      },
      vselect_mounted: function() {
        vselect_init('.v-select__traffic_sources', this.options.traffic_sources, 'traffic_sources', this, { sort_by_integer_id: true })
      },
      vselect_updated: function() {
        vselect_update('.v-select__traffic_sources', this.traffic_sources.map(ts => ts.id))
      }
    }
  })
}